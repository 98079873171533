// Customizable Area Start
//@ts-nocheck
import React from "react";

import {Container,Grid,Typography,Box,Button,Avatar} from "@material-ui/core";

import { locationIcon, clockIcon, profileIcon, defaultPostImage } from "./assets";

import ViewPostController, { Props } from "./ViewPostController";
import { Wrapper } from "./styles";
import { getTimeFromNow } from "../../../components/src/helpers/getTimeFromNow";
import Breadcrumb from "../../../components/src/Breadcrumb.web";
import i18n from "i18next";

export default class ViewPost extends ViewPostController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { post, breadcrumbData, notificationBreadcrumbData } = this.state;
    
    const storedSessionData = sessionStorage.getItem("dataObj");
    const currentUserId = storedSessionData ? JSON.parse(storedSessionData) : {};

    console.log(post, 'post')
    return (
      <Grid container>
        <Wrapper>
          <Container maxWidth={"xl"}>
            {
              this.handleCondition(
              this.state.From === "notifications" ,
                <Breadcrumb data={notificationBreadcrumbData} navigation={this.props.navigation} />
                ,
                <>
                  <Breadcrumb data={breadcrumbData} navigation={this.props.navigation} />
                  <Box className="myPostsTitle">
                    <Typography className="mainHeading"> {i18n.t('Post1')}</Typography>
                  </Box>
                </>
            )}

            <Box className="card">
              <Box data-test-id="user-profile" className="card_title_wrapper" onClick={() => this.goToUserProfile(post?.account_id)}>
                <Avatar
                  className="Post-profile-img"
                  data-test-id="profile-icon"
                  src={post?.profile_pic || profileIcon}
                  alt="Custom Icon"
                />
                <Typography className="card-post-name">
                  {post?.user_name || `${i18n.t("No Name")}`} {i18n.t('created this post')}
                </Typography>
              </Box>
              <div style={{
                position: 'relative',
                display: 'inline-block',
                width: "100%", overflow: "hidden"
              }}>
                <img
                  className="card-image" 
                  src={this.handleCondition(post?.output_image_url , post?.output_image_url , post?.image_url)} 
                  alt="post"
                />
                <div
                  style={{
                    position: 'absolute',transform: 'rotate(-45deg)',opacity: 0.3,fontSize: '40px',fontWeight: 'bold',color: '#fff',left: 0,
                    top: 0,alignItems: 'center',justifyContent: 'center', width: '100%', height: '100%',pointerEvents: 'none',display: 'flex',
                  }}
                >
                  {currentUserId.userEmail}
                </div>
              </div>
              <Typography className="card-post-detail">
                {post?.description || i18n.t("No Descrpition")}
              </Typography>

              <Box className="Post-time-detail">
                <Box className="Post-time-inner">
                  <Button className="Floor_btn" style={{ maxWidth: "50%" }}>
                    <img src={locationIcon} alt="location" />
                    <span className="trimWords">{(post?.work_location) || i18n.t("No Location")}</span>
                  </Button>
                  <Avatar
                    className="Post-inner-profile-img"
                    data-test-id="profile-icon"
                    src={post?.profile_pic || profileIcon}
                    alt="Custom Icon"
                  />
                  <Typography className="Post-time-content">
                    {post?.user_name || `${i18n.t("No Name")}`}
                  </Typography>
                  <img src={clockIcon} alt="time" className="time-icon" />
                  <Typography className="Post-time-content">
                    {getTimeFromNow(post?.created_at)?.split(" ")[0]} {i18n.t("days ago")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {!post?.actioned_or_unactioned_value && (
              <Box className="process-wrapper">
                <Button onClick={this.navigateToMyPosts} variant="contained" className="Ignore-btn">
                {i18n.t("Ignore")}
                </Button>
                <Button
                  data-test-id="proceed-btn"
                  variant="contained"
                  className="process-btn"
                  onClick={this.proceedPostHandler}
                >
                  {i18n.t("Proceed")}
                </Button>
              </Box>
            )
            }
          </Container>
        </Wrapper>
      </Grid>
    );
  }
}

// Customizable Area End
