import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as Yup from "yup";
import { mic, micStart } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAPIRequest } from "../../../components/src/Utils";
import { FunctionData } from "./ReportingInvestigationController.web";
export const configJSON = require("./config");

export interface ISelectOptions {
  value: number | string;
  name: string;
};

// interfaces for leadership users start
interface ILeadershipUserData {
  id: string;
  type: string;
  attributes: IUserAttributes;
};

interface IUserAttributes {
  activated: boolean;
  email: string;
  official_email: string;
  user_name: string | null;
  first_name: string | null;
  last_name: string | null;
  full_name: string;
  gender: string;
  contractor_name: string;
  icf_name: string;
  icf_number: string | null;
  date_of_birth: string;
  country_code: number;
  phone_number: string | null;
  full_phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  profile_image: any[];
  sub_category_id: string | null;
  category_id: string | null;
  role_id: string | null;
  employee_id: string;
  employee_type_id: string | null;
  profile_location_id: string | null;
  country_id: string | null;
  work_location_id: string | null;
  blood_group_id: string | null;
  is_profile_completed: boolean;
  icf_number_code: string;
  is_safety_incharge: boolean;
  level: string | null;
  deactivated: boolean;
  other_work_location: string;
  supervisor_id: number;
  points: number | null;
  platform: string | null;
  role_name: string | null;
  function_name: string | null;
  sub_function_name: string | null;
  employee_type_name: string | null;
  blood_group_name: string | null;
  location_name: string | null;
  country_name: string | null;
  supervisor_name: string;
  work_location_name: string | null;
  broad_risk_category: any[];
  my_goals: any[];
  risk_levels: any[];
  followings: number;
  followers: number;
  posts_count: number;
  posts: IPosts;
};

interface IPosts {
  data: any[];
};

interface IMetaData {
  pagination: IPagination;
};

interface IPagination {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
  current_count: number;
  per_page: number;
};
// interfaces for leadership users end

interface IProfileLocation {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  country_id: number;
}

interface IWorkLocationAttributes {
  name: string;
  profile_location: IProfileLocation;
}

interface IWorkLocationData {
  id: string;
  type: string;
  attributes: IWorkLocationAttributes;
}



// intefaces for load d8 form data start
interface IncidentD8FormData {
  id: string;
  type: string;
  attributes: IncidentD8FormAttributes;
};

interface IncidentD8FormAttributes {
  id: number;
  description: string;
  post_id: number;
  d8_completed: string | null;
  another_member: IMember[];
  another_corrective_actions: ICorrectiveAction[];
  preventive_actions: IPreventiveAction[];
};

// intefaces for load d8 form data end

// interfaces for load post conclude start
interface ISite {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  rank: number | null;
  sub_category_type: string | null;
  category_id: number;
  profile_location_id: number;
  country_id: number;
}

interface IFunctionData {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  profile_location_id: number;
  sub_category_id: number;
  category_id: number;
  country_id: number;
}

interface IRelatedImage {
  url: string;
  filename: string;
  type: string;
}

interface IncidentPostConcludedAttributes {
  id: number;
  site_id: number;
  function_id: number;
  incident_description: string;
  site: ISite;
  function: IFunctionData;
  related_images: IRelatedImage[];
}

interface IncidentPostConcludedData {
  id: string;
  type: string;
  attributes: IncidentPostConcludedAttributes;
}
// interfaces for load post conclude end

export interface IMember {
    id: number | null;
    verified_user_id: string;
    verified_date: string;
    status: string;
    remark: string;
}

export interface ICorrectiveAction {
    id: number | null;
    description: string;
    assigned_person_id: string;
    due_date: string;
}

export interface IPreventiveAction {
    id: number | null;
    description: string;
    assigned_person_id: string;
    due_date: string;
}

export interface ICorrectivePreventiveAction {
    description: string;
    another_members_attributes: IMember[];
    another_corrective_actions_attributes: ICorrectiveAction[];
    preventive_actions_attributes: IPreventiveAction[];
}

export interface IPostCreation {
  site: string | number;
  department: string | number;
  description: string;
  relatedPhotos: File[] | string[] | Blob[];
}

export interface IFormValues {
    corrective_preventive_action: ICorrectivePreventiveAction;
}
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  functionData: FunctionData[];
  handlePrev:  () => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  formValues: IFormValues;
  post_creation: IPostCreation;
  listening: boolean;
  transcription: string;
  stoppedByTimeout: boolean;
  images: string[] | any[];
  showScreen: "Team Recognition" | "Action Assignment" | "Post Creation";
  leadershipUsers: ISelectOptions[];
  delete_another_member_ids: number[];
  delete_another_corrective_action_ids: number[];
  delete_preventive_action_ids: number[];
  responseStatus: "success" | "error" | null;
  errorMsg: string;
  functionData: ISelectOptions[];
  workLocationData: ISelectOptions[];
  loading: boolean;
  // Customizable Area End
}
interface SS {}

export default class IncidentReportingD8Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  private recognition: any | undefined;
  callGetLeadershipUsers: string = "";
  callGetD8FormData: string = "";
  callUpdateD8FormData: string  = "";
  callGetWorkLocationData: string  = "";
  callGetPostConcludeData: string = "";
  callUpdateD8PostCreationData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
      this.state = {
          formValues: {
              corrective_preventive_action: {
                  description: "",
                  another_members_attributes: [
                      {
                          id: null,
                          verified_user_id: "",
                          verified_date: "",
                          status: "",
                          remark: "",
                      },
                  ],
                  another_corrective_actions_attributes: [
                      {
                          id: null,
                          description: "",
                          assigned_person_id: "",
                          due_date: "",
                      },
                  ],
                  preventive_actions_attributes: [
                      {
                          id: null,
                          description: "",
                          assigned_person_id: "",
                          due_date: "",
                      },
                  ],
              },
          },
          post_creation: {
            site: "",
            department: "",
            description: "",
            relatedPhotos: [],
          },
          listening: false,
          transcription: "",
          stoppedByTimeout: false,
          images: [],
          showScreen: "Team Recognition",
          leadershipUsers: [],
          delete_another_member_ids: [],
          delete_another_corrective_action_ids: [],
          delete_preventive_action_ids: [],
          responseStatus: null,
          errorMsg: "",
          functionData: [],
          workLocationData: [],
          loading: false,
      };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getLeadershipUsers();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props) {
    if (prevProps.functionData !== this.props.functionData && this.props.functionData?.length > 0) {
      let functionData = this.props.functionData.map((fn: FunctionData) => ({
        value: fn.attributes?.id || "",
        name: fn.attributes?.name || "",
      }));
  
      this.setState({ functionData }, () => {
        this.getWorkLocationData(functionData[0].value)
      });
    }
  }

    apiSuccessCallBackController = (
      apiRequestCallId: string,
      responseJSON: Record<string, unknown>
    ) => {
      const successCallbackMap = {
          [this.callGetLeadershipUsers]: this.handleGetLeadershipUsersApiResponse,
          [this.callGetD8FormData]: this.handleGetD8FormDataApiResponse,
          [this.callUpdateD8FormData]: this.handleUpdateD8FormDataApiResponse,
          [this.callGetWorkLocationData]: this.handleGetWorkLocationDataApiResponse,
          [this.callGetPostConcludeData]: this.handleGetPostConcludeDataApiResponse,
          [this.callUpdateD8PostCreationData]: this.handleUpdatePostConcludeDataApiResponse,
      };

      if (apiRequestCallId) {
        const successCallback: (responseJSON: Record<string, unknown>) => void =
          successCallbackMap[apiRequestCallId];
        !!successCallback && successCallback(responseJSON);
      }
    };

    handleGetLeadershipUsersApiResponse = (responseJSON: Record<string, unknown>) => {
      if (this.handleErrorResponse(responseJSON)) {
        return;
      }

        const response = responseJSON as {
          leadership_user: {
            meta?: IMetaData;
            data?: ILeadershipUserData[];
          }
        };

         if(response.leadership_user.data){
          let data = response.leadership_user.data;
          let leadershipUsers: ISelectOptions[] = [];
 
          data.forEach((user) => {
            leadershipUsers.push(
             {
              value: user.id,
              name: user.attributes.full_name,
            });
          });

          this.setState({ leadershipUsers }, () => {
            this.getD8FormData();
          });
         }
    };

    handleGetWorkLocationDataApiResponse = (responseJSON: Record<string, unknown>) => {
      if (this.handleErrorResponse(responseJSON)) {
        return;
      }

        const response = responseJSON as {
          work_locations: {
            data?: IWorkLocationData[];
          }
        };

         if(response.work_locations.data){
          let data = response.work_locations.data;
          let workLocationData: ISelectOptions[] = [];
 
          data.forEach((location) => {
            workLocationData.push(
             {
              value: location.id,
              name: location.attributes.name,
            });
          });

          this.setState({ workLocationData });
         }
    };

    handleGetPostConcludeDataApiResponse = async(responseJSON: Record<string, unknown>) => {
      if (this.handleErrorResponse(responseJSON)) {
        return;
      }

        const response = responseJSON as {
            data?: IncidentPostConcludedData;
        };

         if(response.data?.attributes){
          let attributes = response.data.attributes;

         
           let relatedPhotos = attributes.related_images.map((img) => {
             return img.url;
           });

          this.setState({ images: relatedPhotos });

           let post_creation = {
             site: attributes.site_id,
             department: attributes.function_id,
             description: attributes.incident_description,
             relatedPhotos,
           }

           this.setState({ post_creation }, () => {
            this.getWorkLocationData(post_creation.site)
          });
         }
    };

    handleUpdatePostConcludeDataApiResponse = async(responseJSON: Record<string, unknown>) => {
      this.setState({ loading: false });
      if (this.handleErrorResponse(responseJSON)) {
        return;
      }

      this.setState({ responseStatus: "success", errorMsg: "data saved successfully"});
    };

    handleGetD8FormDataApiResponse = (responseJSON: Record<string, unknown>) => {
      if (this.handleErrorResponse(responseJSON)) {
        return;
      }

        const response = responseJSON as {
          data: IncidentD8FormData;
        };

         if(response.data){
          let attributes = response.data.attributes;

          let formValues = {
            ...this.state.formValues,
            corrective_preventive_action: {
              description: attributes.description,
              another_members_attributes: attributes.another_member.length > 0 ? attributes.another_member.map((member) => {
                return {
                    id: member.id,
                    verified_user_id: member.verified_user_id,
                    verified_date: member.verified_date,
                    status: member.status,
                    remark: member.remark,
                }
              }) : [{
                id: null,
                verified_user_id: "",
                verified_date: "",
                status: "",
                remark: "",
              }],
              another_corrective_actions_attributes: attributes.another_corrective_actions.length > 0 ? attributes.another_corrective_actions.map((correctiveAction) => {
                return {
                  id: correctiveAction.id,
                  description: correctiveAction.description,
                  assigned_person_id: correctiveAction.assigned_person_id,
                  due_date: correctiveAction.due_date,
                }
              }) : [
                {
                  id: null,
                  description: "",
                  assigned_person_id: "",
                  due_date: "",
                },
              ],
              preventive_actions_attributes: attributes.preventive_actions.length > 0 ? attributes.preventive_actions.map((preventiveAction) => {
                return {
                  id: preventiveAction.id,
                  description: preventiveAction.description,
                  assigned_person_id: preventiveAction.assigned_person_id,
                  due_date: preventiveAction.due_date,
                }
              }) : [
                {
                  id: null,
                  description: "",
                  assigned_person_id: "",
                  due_date: "",
                },
              ],
          },
          }

          this.setState({ formValues });
         }
    };

    handleUpdateD8FormDataApiResponse = (responseJSON: Record<string, unknown>) => { 
      if (responseJSON.message !== "D8 from update successfully") { // this message is set in the response
        this.setState({ responseStatus: "error", errorMsg: "somthing went wrong"})
        return;
      }

      this.setState({ responseStatus: "success", errorMsg: "data saved successfully"})
      this.getD8FormData();
    }

    handleErrorResponse = (responseJSON: Record<string, unknown>) => {
      const { 
        errors: possibleErrors 
      } = responseJSON;
  
      if (possibleErrors) {
        return true;
      }
      return false;
    };

    getLeadershipUsers = async () => {
      const token = await getStorageData("authToken");
  
      this.callGetLeadershipUsers = sendAPIRequest(
          configJSON.leaderShipEndpoint,
          {
            method: configJSON.getApiMethodType,
            headers: {
              token,
            },
          }
        );
    };

    getWorkLocationData = async (id: number | string) => {
      const token = await getStorageData("authToken");
  
      this.callGetWorkLocationData = sendAPIRequest(
          `${configJSON.workLocationsEndPoint}?sub_function_id=${id}`,
          {
            method: configJSON.getApiMethodType,
            headers: {
              token,
            },
          }
        );
    };

    getD8FormData = async () => {
      const token = await getStorageData("authToken");
      let createPostId = await getStorageData("createPostId");
  
      this.callGetD8FormData = sendAPIRequest(
          `${configJSON.loadD8FormEndPoint}?id=${createPostId}`,
          {
            method: configJSON.getApiMethodType,
            headers: {
              token,
            },
          }
        );
    };

    loadPostConclude = async() => {
      const token = await getStorageData("authToken");
      let createPostId = await getStorageData("createPostId");
  
      this.callGetPostConcludeData = sendAPIRequest(
          `${configJSON.loadPostConcludeAPIEndPoint}?id=${createPostId}`,
          {
            method: configJSON.getApiMethodType,
            headers: {
              token,
            },
          }
        );
    };


    formD8Validation = (showScreen: string) => Yup.object().shape({
      corrective_preventive_action: Yup.object().when([], {
          is: () => showScreen === "Team Recognition",
          then: Yup.object().shape({
            description: Yup.string().nullable().required("Please enter lesson learnt"),
            another_members_attributes: Yup.array().of(
              Yup.object().shape({
                verified_user_id: Yup.string().nullable().required("Please select person"),
                verified_date: Yup.string().nullable().required("Please select verified date"),
                status: Yup.string().nullable().required("Please select status"),
                remark: Yup.string().nullable().required("Please enter remark"),
              })
            )
          }),
          otherwise: Yup.object().nullable(),
        }).when([], {
          is: () => showScreen === "Action Assignment",
          then: Yup.object().shape({
            another_corrective_actions_attributes: Yup.array().of(
              Yup.object().shape({
                description: Yup.string().nullable().required("Please enter brief description"),
                assigned_person_id: Yup.string().nullable().required("Please select assigned person"),
                due_date: Yup.string().nullable().required("Please select due date"),
              })
            ),
            preventive_actions_attributes: Yup.array().of(
              Yup.object().shape({
                description: Yup.string().nullable().required("Please enter brief description"),
                assigned_person_id: Yup.string().nullable().required("Please select assigned person"),
                due_date: Yup.string().nullable().required("Please select due date"),
              })
            )
          }),
        }),
        post_creation: Yup.object().when([], {
          is: () => showScreen === "Post Creation",
          then: Yup.object().shape({
            site: Yup.string().nullable().required("Please select site"),
            department: Yup.string().nullable().required("Please select Function / Area / BU / Department"),
            description: Yup.string().nullable().required("Please enter brief description"),
            relatedPhotos: Yup.array().nullable().min(1, "Image is required"),
          }),
        })
    });

    formD8PostCreationValidation = Yup.object().shape({
      site: Yup.string().nullable().required("Please select site"),
      department: Yup.string().nullable().required("Please select Function / Area / BU / Department"),
      description: Yup.string().nullable().required("Please enter brief description"),
      relatedPhotos: Yup.array().nullable().min(1, "Image is required"),
    });

    handleSubmitD8From = async (values: IFormValues) => {
      const token = await getStorageData("authToken");
      let createPostId = await getStorageData("createPostId");

      let attributes = values.corrective_preventive_action;

      let corrective_preventive_action = {
        delete_another_member_ids: this.state.delete_another_member_ids,
        delete_another_corrective_action_ids: this.state.delete_another_corrective_action_ids,
        delete_preventive_action_ids: this.state.delete_preventive_action_ids,
        description: attributes.description,
        another_members_attributes: (attributes.another_members_attributes[0].verified_user_id !== "")? attributes.another_members_attributes.map((member) => {
          return {
            id: member.id,
            verified_user_id: member.verified_user_id,
            verified_date: member.verified_date,
            status: member.status,
            remark: member.remark,
          }
        }): [],
        another_corrective_actions_attributes: (attributes.another_corrective_actions_attributes[0].assigned_person_id !== "") ? attributes.another_corrective_actions_attributes.map((correctiveAction) => {
          return {
            id: correctiveAction.id,
            description: correctiveAction.description,
            assigned_person_id: correctiveAction.assigned_person_id,
            due_date: correctiveAction.due_date,
          }
        }) : [],
        preventive_actions_attributes: (attributes.preventive_actions_attributes[0].assigned_person_id !== "") ? attributes.preventive_actions_attributes.map((preventiveAction) => {
          return {
            id: preventiveAction.id,
            description: preventiveAction.description,
            assigned_person_id: preventiveAction.assigned_person_id,
            due_date: preventiveAction.due_date,
          }
        }) : [],
      };
  
      this.callUpdateD8FormData = sendAPIRequest(
          configJSON.updateD8FormEndPoint,
          {
            method: configJSON.patchApiMethodType,
            headers: {
              "Content-Type": configJSON.dashboarContentType,
              token,
            },
            body: {
              id: createPostId,
              corrective_preventive_action: {
                ...corrective_preventive_action
              },
            },
          }
        );
    };

    handleSubmitD8PostCreationFrom = async (values: IPostCreation) => {
      this.setState({ loading: true });
      let formdata = new FormData();
      const token = await getStorageData("authToken");
      let createPostId = await getStorageData("createPostId");

      formdata.append("post[id]", createPostId);
      formdata.append("post[site_id]", values.site as string);
      formdata.append("post[function_id]", values.department as string);
      formdata.append("post[incident_description]", values.description);
      values.relatedPhotos.forEach((img: string | File | Blob) => {
        if(typeof img !== "string")
          formdata.append("post[related_images][]", img as Blob);
      });


      this.callUpdateD8PostCreationData = sendAPIRequest(
        configJSON.updateFinalStepAPIEndPoint,
        {
          method: configJSON.patchApiMethodType,
          headers: {
            token,
          },
          body: formdata,
        }
      );
      
    };

    renderMic() {
        return this.state.listening ? micStart : mic;
    };

    toggleListening = async () => {
        if (this.recognition) {
          if (this.state.listening) {
            this.setState({ stoppedByTimeout: true });
            this.recognition.stop();
          } else {
            this.setState({ stoppedByTimeout: false });
            await this.recognition.start();
            this.setState({ transcription: "" });
            setTimeout(() => {
              if (this.state.listening && !this.state.stoppedByTimeout) {
                this.recognition.stop();
                this.setState({ listening: false });
              }
            }, 30000);
    
          }
          this.setState((prevState) => ({
            listening: !prevState.listening,
          }));
        }
      };

      setMikeData = async (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
        setFieldValue(
          "description",
          this.state.formValues.corrective_preventive_action.description + " " + this.state.transcription
        );
      };

      handleUpload = async(
        files: FileList, 
        setFieldValue: {
          (field: string,
              value: any,
              shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
      }
      ) => {
        const newImages = Array.from(files).map((file) => file);
        const newImagesUrl = Array.from(files).map((file) =>
          URL.createObjectURL(file)
        );
        this.setState((prevState) => ({
          images: [...prevState.images, ...newImagesUrl],
        }), () => setFieldValue("relatedPhotos", newImages));
      };
    
      handleRemove = async(
        index: number,
        setFieldValue: {
          (field: string,
            value: any,
            shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }
      ) => {
        this.setState((prevState) => ({
          images: prevState.images.filter((_, i) => i !== index),
        }), () => setFieldValue("relatedPhotos", this.state.images));
      };

      handleNext = () => {
        switch(this.state.showScreen){
          case "Team Recognition": 
            return this.setState({ showScreen: "Action Assignment" });
          case "Action Assignment":
            return this.setState({ 
              showScreen: "Post Creation" 
            }, () => {
              this.loadPostConclude()
            });
          case "Post Creation": 
            return this.setState({ showScreen: "Team Recognition" });
        }
      }

      handlePrevClick = () => {
        switch(this.state.showScreen){
          case "Team Recognition": 
            return this.props.handlePrev();
          case "Action Assignment":
            return this.setState({ 
              showScreen: "Team Recognition" 
            });
        }
      }

      handleCloseSnackbar = () => {
        this.setState({ responseStatus: null, errorMsg: ""});
      }
  // Customizable Area End
}

