import React from "react";
// Customizable Area Start
import { Box, FormGroup, FormHelperText, IconButton, MenuItem, Modal, Select, styled, Typography, Grid, TextField, Button, FormControlLabel, Checkbox, InputAdornment, Input } from "@material-ui/core";
import IncidentController, { Props, SelectEventType } from "./IncidentController.web";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { webStyle } from "../src/postCreationPopUp/styles";
import { upload } from "./assets";
import * as IMG_CONST from "./assets";
import DatePicker from "react-datepicker";
import DeleteIcon from "@material-ui/icons/Delete";
import Loader from "../../../components/src/Loader.web";
import { StyledDropdownIcon } from "./postCreationPopUp/PostCreationPopUp.web";
import TimerIcon from '@material-ui/icons/Timer';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// Customizable Area End

export default class Incident extends IncidentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  incidentModal = () => {
    return (
      <IncidentModal
        open={this.props.isIncident}
        onClose={this.handleIncidentOpenClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box className="incidentBox" >
          <Box style={{
            display: "flex", 
            flexDirection: "row", 
            justifyContent: "space-between",
            padding: '10px 20px'
          }}>

            <Typography data-test-id="headingText" className="headingPost">Post Creation</Typography>
            <img src={IMG_CONST.modalClose} onClick={this.props.onModalClick} />
          </Box>
          <Loader loading={this.state.isLoader} />
          {this.renderForm()}
        </Box>
      </IncidentModal>
    )
  }

  renderForm = () => {
    switch (this.state.incidentStep) {
      case 1:
        return this.incidentFormOne()
      case 2:
        return this.incidentFormTwo()
      case 3:
        return this.incidentFormThree()
      case 4:
        return this.renderD1Form()
      case 5:
        return this.renderD1PartTwoForm()
    }
  };

  incidentFormOne = () => {
    const currentLoggedinUser = JSON.parse(sessionStorage.getItem("dataObj") || "{}");
    return (
      <>
        <Formik
        enableReinitialize={true}

          data-test-id="incidentFormOneTestID"
          initialValues={this.state.formOneData}
          validationSchema={this.validationSchema}
          onSubmit={this.handleFormOneSubmit}
         >
          {({ setFieldValue, values }) => (
            <Form translate={undefined}
          className="formStyle"
              data-test-id="formTestId"
              
            >
               <Box>
                <Grid container >
                <Grid container spacing={2}>
                                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Box>
                        <Typography className="fieldText">Employee ID</Typography>
                        <Field
                          name="employeeId"
                          as={TextField}
                          placeholder={currentLoggedinUser.Emp_Id}
                          className="inputField disaledClass"
                          fullWidth
                          disabled
                        />
                      </Box>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Box>
                        <Typography className="fieldText">IIR No</Typography>
                        <Field
                          name="iirNo"
                          as={TextField}
                          placeholder={this.state.iirNo}
                          className="inputField disaledClass"
                          disabled
                          fullWidth
                        />
                      </Box>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box>
                        <Typography className="fieldText">You want to Report</Typography>
                        <Field
                          data-test-id="reportTestId"
                          name="report"
                          as={Select}
                          IconComponent={StyledDropdownIcon}
                          renderValue={
                            values.report
                              ? undefined
                              : () => (
                                <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                                  Select Report
                                </div>
                              )
                          }
                          displayEmpty
                          onChange={(event: SelectEventType) => setFieldValue("report", event.target.value)}
                          fullWidth
                          className="inputFieldDropDown"
                          MenuProps={{
                            disableScrollLock: true,
                            anchorOrigin: { horizontal: "left", vertical: "bottom" },
                            transformOrigin: { vertical: "top", horizontal: "left" },
                            getContentAnchorEl: null,
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                                overflowY: "auto",
                              },
                            },
                          }}
                        >
                          <CustomMenuItem value="" disabled>
                            Select Report
                          </CustomMenuItem>
                          {this.state.reportData.map((option) => (
                            <CustomMenuItem key={option.id} value={option.id}>
                              {option.label}
                            </CustomMenuItem>
                          ))}
                        </Field>
                        <ErrorMessage name="report" component="div" className="errorTxt" />
                      </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Box>
                        <Typography className="fieldText">Function / Area / BU / Department</Typography>
                        <Field
                          data-test-id="functionTestId"
                          IconComponent={StyledDropdownIcon}
                          renderValue={
                            values.function
                              ? undefined
                              : () => (
                                <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                                  Select Function
                                </div>
                              )
                          }
                          name="function"
                          as={Select}
                          displayEmpty
                          onChange={(event: React.ChangeEvent<{ value: unknown; }>) => {
                            this.onFunctionChange(event, setFieldValue);
                          }}
                          
                          fullWidth
                          className="inputFieldDropDown"
                          MenuProps={{
                            disableScrollLock: true,
                            anchorOrigin: { horizontal: "left", vertical: "bottom" },
                            transformOrigin: { vertical: "top", horizontal: "left" },
                            getContentAnchorEl: null,
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                                overflowY: "auto",
                              },
                            },
                          }}
                        >
                          <CustomMenuItem value="" disabled>
                            Select Function
                          </CustomMenuItem>
                          {this.state.functionData.map((option) => (
                            <CustomMenuItem key={option.attributes.id} value={option.attributes.id}>
                              {option.attributes.name}
                            </CustomMenuItem>
                          ))}
                        </Field>
                        <ErrorMessage name="function" component="div" className="errorTxt" />
                      </Box>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Box>
                        <Typography className="fieldText">Site</Typography>
                        <Field
                          data-test-id="siteTestId"
                          IconComponent={StyledDropdownIcon}
                          name="site"
                          as={Select}
                          displayEmpty
                          renderValue={
                            values.site
                              ? undefined
                              : () => (
                                <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                                  Select Site

                                </div>
                              )
                          }
                          onChange={(event: SelectEventType) => setFieldValue('site', event.target.value)}
                          fullWidth
                          className="inputFieldDropDown"
                          MenuProps={{
                            disableScrollLock: true,
                            anchorOrigin: { horizontal: "left", vertical: "bottom" },
                            transformOrigin: { vertical: "top", horizontal: "left" },
                            getContentAnchorEl: null,
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                                overflowY: "auto",
                              },
                            },
                          }}
                        >
                          <CustomMenuItem value="" disabled>
                            Select Site
                          </CustomMenuItem>
                          <CustomMenuItem value={this.state.siteData}>
                            {this.state.siteData}
                          </CustomMenuItem>
                  
                        </Field>
                        <ErrorMessage name="site" component="div" className="errorTxt" />
                      </Box>
                    </Grid>

                    

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box>
                        <FormGroup className="input-block" style={webStyle.wideInputBlock as React.CSSProperties}>
                          <FormHelperText className="fieldText">Related Photo / Sketch</FormHelperText>
                          <Button component="label" style={{ ...webStyle.input, ...webStyle.upload as React.CSSProperties }}>
                            <div className="upload-img" style={webStyle.uploadImg as React.CSSProperties}>
                              <img src={this.state.upldFileLink || upload} alt="upload image" style={webStyle.iploadedImg as React.CSSProperties} />
                              <div style={webStyle.fontBlock}>Upload Image</div>
                            </div>
                            <input
                              type="file"
                              accept="image/png, image/jpeg"
                              multiple
                              data-test-id="pictureImgTestId"
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFileChange(event, setFieldValue)}
                            />
                          </Button>
                        </FormGroup>
                        {this.state.inicidentRelateImages.length > 0 && (
                          <>
                            <FormHelperText className="fieldText">Uploaded Images {this.state.inicidentRelateImages.length}</FormHelperText>
                            <Box style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}>
                              {this.state.inicidentRelateImages.map((image, index) => (
                                <Box key={index} style={{ position: 'relative', margin: '5px', borderRadius: "8px", height: "70px", width: "70px", border: 'grey solid 0.5' }}>
                                  <IconButton
                                  data-test-id ="iconButtonTestId"
                                    style={{ position: "absolute", top: 5, right: 5, backgroundColor: "#FFF", height: "15px", width: "15px", padding: "0px" }}
                                    onClick={() => {
                                      this.setState((prevState) => {
                                        const updatedImages = prevState.inicidentRelateImages.filter((_, i) => i !== index);
                                        setFieldValue("uploadedImages", updatedImages);
                                        return { inicidentRelateImages: updatedImages };
                                      });
                                    }}
                                  >
                                    <img src={IMG_CONST.closeIcon} style={{ backgroundColor: "#FFF", height: "10px", width: "10px" }} />
                                  </IconButton>
                                  <img src={image} alt={`Uploaded Preview ${index + 1}`} style={{ height: "70px", width: "70px", objectFit: 'cover' }} />
                                </Box>
                              ))}
                            </Box>
                          </>
                        )}
                        <ErrorMessage name="uploadedImages" component="div" className="errorTxt" />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <FormGroup className="input-block" style={{ ...webStyle.wideInputBlock, ...webStyle.customTopIndent } as React.CSSProperties}>
                <img
                  onClick={this.handleSpeech}
                  height="21px"
                  width="21px"
                  src={this.renderMic()}
                  alt="mic icon"
                  style={webStyle.mic as React.CSSProperties}

                  data-test-id="speechText"
                />
                 <Typography className="fieldText">Brief Description</Typography>

                 <Field
                  data-test-id="spokenTextTestId"
                  value={this.state.spokenText}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = event.target.value;
                     this.setState({ spokenText: newValue }, () => {
                       if (setFieldValue) {
                        setFieldValue("description", newValue);
                      }
                    });
                  }}
                  multiline
                  maxRows={4}
                  minRows={3}
                  name="description"
                  as={TextField}
                  placeholder="Brief Description"
                  className="textarea"
                  fullWidth
                  style={{ ...webStyle.textareaStyle }}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      border: "none",
                      "& fieldset": { border: "none" },
                    },
                  }}
                />


               <ErrorMessage name="description" component="div" className="errorTxt" />

              </FormGroup>
              
             {this.renderNavigation()}
            </Form>
          )}
        </Formik>
        </>
     );
  };

  incidentFormTwo = () => {
     return (
      <Formik
      enableReinitialize={true}
      key={JSON.stringify(this.state.formTwoData)} 
        data-test-id="incidentFormSecondTestID"
         initialValues={this.state.formTwoData}
        validationSchema={this.formTwovalidationSchema}
        onSubmit={this.handleFormTwoSubmit}
 
      >
        {({ setFieldValue, values }) => (
          <Form
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
        className="formStyle"
            translate={undefined}
          >
            <Grid container spacing={2}>
              {this.renderShiftField(values.shift, setFieldValue)}
              {this.renderTimeField(values.time, setFieldValue)}
              {this.renderLocationField(values.locations, setFieldValue)}
            </Grid>

            {this.renderNavigation()}
          </Form>
        )}
      </Formik>
    );
  };

  renderShiftField = (
    shiftValue: string,
    setFieldValue: (field: string, value: string) => void
  ) => (
    <Grid item xs={6}>
      <Typography className="fieldText">Shift</Typography>
      <Field
        data-test-id="shiftTestId"
        IconComponent={StyledDropdownIcon}
        name="shift"
        as={Select}
        displayEmpty
        renderValue={
          shiftValue
            ? undefined
            : () => (
              <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                Select Shift
              </div>
            )
        }
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          setFieldValue("shift", event.target.value as string)
        }
        fullWidth
        className="inputFieldDropDown"
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: 300,
              overflowY: "auto",
            },
          },
        }}
      >
        <CustomMenuItem value="" disabled>
          Select Shift
        </CustomMenuItem>
        {this.state.shiftData.data.map((option) => (
          <CustomMenuItem key={option.id} value={option.id}>
            {option.attributes.name}
          </CustomMenuItem>
        ))}

      </Field>
      <ErrorMessage name="shift" component="div" className="errorTxt" />
    </Grid>
  );

  renderTimeField = (
    timeValue: string,
    setFieldValue: (field: string, value: string) => void
  ) => (
    <Grid item xs={6}>
      <Typography className="fieldText">Time</Typography>
      <InputWrapper>
      <TimeInput
        data-test-id="timeTextId"
        type="time"
        value={timeValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue("time", event.target.value)
        }
        placeholder="Select Time"
        className="inputField"
      />
      <Icon />
    </InputWrapper>
      <ErrorMessage name="time" component="div" className="errorTxt" />
    </Grid>
  );

  renderLocationField = (
    locationValue: string,
    setFieldValue: (field: string, value: string) => void
  ) => (
    <Grid item xs={12}>
      <Typography className="fieldText">Location of Incident</Typography>
      <Field
        data-test-id="locationsTestId"
        IconComponent={StyledDropdownIcon}
        name="locations"
        as={Select}
        displayEmpty
        onChange={(event: React.ChangeEvent<{ value: string }>) => {
           const value = event.target.value;
          setFieldValue("locations", value);
          this.setState({ locationV: value });
        }}
        renderValue={
          locationValue
            ? undefined
            : () => (
              <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                Select locations
              </div>
            )
        }
        fullWidth
        className="inputFieldDropDown"
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: 300,
              overflowY: "auto",
            },
          },
        }}
      >
        <CustomMenuItem value="" disabled>
          Select Location
        </CustomMenuItem>
        {this.state.functionData.map((option) => (
          <CustomMenuItem key={option.attributes.id} value={option.attributes.id}>
            {option.attributes.name}
          </CustomMenuItem>
        ))}
      </Field>
      <ErrorMessage name="locations" component="div" className="errorTxt" />
    </Grid>
  );


  renderInjuredPerson = (
    injured: { injuredPerson: string | unknown },
    setFieldValue: (field: string, value: string) => void
  ) => {
    return (
      <Field
        data-test-id="injuredPersonTestId"
        name="injuredPerson"
        as={Select}
        IconComponent={StyledDropdownIcon}
        value={injured.injuredPerson} 
        renderValue={
          injured.injuredPerson
            ? undefined
            : () => (
              <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                Select Injured Person
              </div>
            )
        }
        displayEmpty
        onChange={(event: SelectEventType) => setFieldValue("injuredPerson", event.target.value as string)}
        fullWidth
        className="inputFieldDropDown"
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: 300,
              overflowY: "auto",
            },
          },
        }}
      >
        <CustomMenuItem value="" disabled>Select Injured Person</CustomMenuItem>
        {this.state?.accountData?.map((account, index) => (
          <CustomMenuItem key={index} value={account.id}>
            {account.full_name}
          </CustomMenuItem>
        ))}
      </Field>
    );
  };
  
  renderEmployeeId = (values: { employeeId: string }, setFieldValue: (field: string, value: unknown) => void) => {    
    const currentLoggedinUser = JSON.parse(sessionStorage.getItem("dataObj") || "{}");
    return (
      <Field
        name="employeeId"
        as={TextField}
        placeholder={currentLoggedinUser.Emp_Id}
        className="inputField disaledClass"
        fullWidth
        disabled
      />
    );
  };

  renderContractorName = (contractorName: string, setFieldValue: (field: string, value: unknown) => void) => {
    return (
      <Field
        data-test-id="contractorNameTestId"
        name="contractorName"
        as={Select}
        IconComponent={StyledDropdownIcon}
        displayEmpty
        fullWidth
        value={contractorName} 
        className="inputFieldDropDown"
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          setFieldValue("contractorName", event.target.value)
        }
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: 300,
              overflowY: "auto",
            },
          },
        }}
        renderValue={
          contractorName
            ? undefined
            : () => (
              <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                Select Contractor Name
              </div>
            )
        }
      >
        <CustomMenuItem value="" disabled>Select Contractor Name</CustomMenuItem>
        {this.state.accountData?.map((account, index) => (
          <CustomMenuItem key={index} value={account.id}>
            {account.full_name}
          </CustomMenuItem>
        ))}

      </Field>
    );
  };

  renderPartOfBodyInjured = (
    values: { partOfBodyInjured: string },
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    return (
      <Field
        data-test-id="partOfBodyInjuredTestId"
        name="partOfBodyInjured"
        as={Select}
        IconComponent={StyledDropdownIcon}
        displayEmpty
        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
          setFieldValue("partOfBodyInjured", event.target.value)
        }
        fullWidth
        className="inputFieldDropDown"
        value={values.partOfBodyInjured}
        renderValue={
          values.partOfBodyInjured ? undefined : () => (
            <div style={webStyle.selectPlaceholder as React.CSSProperties}>
              Select Part of Body Injured
            </div>
          )
        }
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: 300,
              overflowY: "auto",
            },
          },
        }}
      >
        <CustomMenuItem value="" disabled>Select Part of Body Injured</CustomMenuItem>
  
        {this.state.bodyPart?.map((part) => (
          <CustomMenuItem key={part.id} value={part.name}>
            {part.name}
          </CustomMenuItem>
        ))}
      </Field>
    );
  };
  


  renderTypeOfInjury = (setFieldValue: (field: string, value: unknown) => void, selectedValue: string) => {
    return (
     
      <Field
        data-test-id="typeOfInjuryTestId"
        name="typeOfInjury"
        as={Select}
        IconComponent={StyledDropdownIcon}
        displayEmpty
        fullWidth
        className="inputFieldDropDown"
        value={selectedValue}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          setFieldValue("typeOfInjury", event.target.value);
          this.handleInjuryChange(event);
        }}
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: 300,
              overflowY: "auto",
            },
          },
        }}
        renderValue={selectedValue ? undefined : () => (
          <div style={webStyle.selectPlaceholder as React.CSSProperties}>
            Select Type of Injury
          </div>
          )
        }
      >
        <CustomMenuItem value="" disabled>
          Select Type of Injury
        </CustomMenuItem>
        {this.state.injuryData?.map((item) => (
          <CustomMenuItem key={item.id} value={item.name}>
            {item.name}
          </CustomMenuItem>
        ))}
      </Field>
    );
  };

  renderEyewitness = (
    eyewitness: string,
    setFieldValue: (field: string, value: unknown) => void
  ) => {
    return (
      <Field
        data-test-id="eyewitnessTestId"
        name="eyewitness"
        as={Select}
        IconComponent={StyledDropdownIcon}
        displayEmpty
        onChange={(event: SelectEventType) => {
          const selectedValue = event.target.value as string;
          setFieldValue("eyewitness", selectedValue);
          this.handleEyewitnessChange(selectedValue);
        }}
        fullWidth
        className="inputFieldDropDown"
        MenuProps={{
          disableScrollLock: true,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: 300,
              overflowY: "auto",
            },
          },
        }}
        renderValue={
          eyewitness
            ? undefined
            : () => (
              <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                Select Eyewitness
              </div>
            )
        }
      >
        <CustomMenuItem value="" disabled>
          Select Eyewitness
        </CustomMenuItem>
        {this.state.accountData?.map((account, index) => (
          <CustomMenuItem key={index} value={account.full_name}>
            {account.full_name}
          </CustomMenuItem>
        ))}
      </Field>
    );
  };
  
  renderFitnessCertificate = (index: number) => {
    return (
      <>
      <Box
        className="inputField"
        data-test-id="fitnessTextId"
        onClick={() => document.getElementById(`fitnessFileInput-${index}`)?.click()}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px",
          backgroundImage: this.state.selectedFitnessCertificate[index]
            ? `url(${this.state.selectedFitnessCertificate[index]})`
            : "none",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          cursor: "pointer",
        }}
      >
        {!this.state.selectedFitnessCertificate[index] && (
          <>
          <Typography 
            style={{ 
              color: "#DCDCDC"
            }}
          >Upload Fitness Certificate</Typography>

        <IconButton
        size="small"
        data-test-id="stopPropagationTestId"
        onClick={(event) => {
          event.stopPropagation();
          document.getElementById(`fitnessFileInput-${index}`)?.click();
        }}
      >
        <img src={IMG_CONST.uploadImg} alt="Upload" style={{ width: 24, height: 24 }} />
      </IconButton></>
        )}
  
        <input
          type="file"
          id={`fitnessFileInput-${index}`}
          onChange={(event) => this.handleFitnessFileChange(event, index)}
          style={{ display: "none" }}
        />
      </Box>
      </>
    );
  };
  
   
  renderJoiningDate = ({ values, setFieldValue }: { values: { joiningDate: string }; setFieldValue: (field: string, value: string) => void }) => {
    return (
      <DatePicker
        data-test-id="datePickerTextId"
        className="inputFieldDate"
        scrollableYearDropdown
        showYearDropdown
        yearDropdownItemNumber={100}
        dateFormat="dd/MM/yyyy"
        dateFormatCalendar="MMMM"
        name="joiningDate"
        placeholderText="Select Date"
        wrapperClassName="date-picker"
        customInput={
          <TextField
            fullWidth
            value={values.joiningDate ? values.joiningDate : ""}
            placeholder="Select Date"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={IMG_CONST.calendarIcon}
                    alt="Calendar Icon"
                    style={{ cursor: "pointer", width: 24, height: 24 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        }
        popperClassName=""
        minDate={new Date()}
        selected={values.joiningDate ? new Date(values.joiningDate) : null}
        onChange={(date) => setFieldValue("joiningDate", date instanceof Date && !isNaN(date.getTime()) ? date.toISOString() : "")}
        />
    );
  };
  

  incidentFormThree = () => {
    return (
      <Formik
        enableReinitialize={true}
        key={JSON.stringify(this.state.savedValues)} 
        data-test-id="incidentFormThirdTestID"
        initialValues={this.state.savedValues}   
        
        validationSchema={this.formThreeValidationSchema}
        onSubmit={this.handleFormThreeSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form className="formStyle" translate={undefined}
         >
 
            <FieldArray name="injurerds"
            
            data-test-id="formThreeTestId"
            >
              {({ push, remove }) => (
                <>
                  {(values.injurerds || [1]).map((injured, index) => (
                    <Grid container spacing={2} key={index} data-test-id="gridTestId" style={{ marginBottom: "24px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {index > 0 && (
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <Button className="removeBtn" data-test-id="removeBtnTstId" onClick={() => remove(index)} startIcon={<DeleteIcon style={{ color: "red" }} />}>
                                Remove
                              </Button>
                            </Grid>
                          )}
              <Typography className="fieldText" >Brief Description</Typography>
 
                        <TextField
                          value={values.injurerds[index].briefDescription}
                          data-test-id="briefDescriptionTestId"
                          name={`injurerds.${index}.briefDescription`}
                          placeholder="Brief Description"
                          multiline
                          rows={4}
                          variant="outlined"
                          fullWidth
                          onChange={(event) =>
                            setFieldValue(`injurerds.${index}.briefDescription`, event.target.value)
                          }
                        />

                         <ErrorMessage name={`injurerds.${index}.briefDescription`} component="div" className="errorTxt" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography className="fieldText">Injured Person Name</Typography>
                        {this.renderInjuredPerson(injured, (field, value) =>
                          setFieldValue(`injurerds.${index}.${field}`, value)
                        )}
                        <ErrorMessage name={`injurerds.${index}.injuredPerson`} component="div" className="errorTxt" />
                      </Grid>

                      <Grid item xs={12} sm={6} style={{ padding: "28px" }}>
                        <StyledFormControlLabel
                          control={
                            <CustomCheckbox
                              data-test-id="checkboxTestId"
                              checked={injured.injured}
                              onChange={(event) =>
                                setFieldValue(`injurerds.${index}.injured`, event.target.checked)
                              }
                              name={`injurerds.${index}.injured`}
                              color="primary"
                              icon={<CustomIcon />}                            />
                          }
                          label="Injured person is contract worker"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography className="fieldText">Employee ID</Typography>
                        {this.renderEmployeeId(injured, (field, value) =>
                          setFieldValue(`injurerds.${index}.${field}`, value)
                        )}
                      </Grid>


                      <Grid item xs={12} sm={6}>
                        <Typography className="fieldText">Contractor Name (if involved)</Typography>
                        {this.renderContractorName(injured.contractorName, (field, value) =>
                          setFieldValue(`injurerds.${index}.${field}`, value)
                        )}
                        <ErrorMessage name={`injurerds.${index}.contractorName`} component="div" className="errorTxt" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography className="fieldText">Joining Date</Typography>
                        {this.renderJoiningDate({
                          values: injured,
                          setFieldValue: (field, value) =>
                            setFieldValue(`injurerds.${index}.${field}`, value),
                        })}
                        <ErrorMessage name={`injurerds.${index}.joiningDate`} component="div" className="errorTxt" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography className="fieldText">Part of Body Injured</Typography>
                        {this.renderPartOfBodyInjured(injured, (field, value) =>
                          setFieldValue(`injurerds.${index}.${field}`, value)
                        )}
                        <ErrorMessage name={`injurerds.${index}.partOfBodyInjured`} component="div" className="errorTxt" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography className="fieldText">Type of Injury</Typography>
                        {this.renderTypeOfInjury((field, value) =>
                          setFieldValue(`injurerds.${index}.${field}`, value),
                          injured.typeOfInjury
                        )}
                        <ErrorMessage name={`injurerds.${index}.typeOfInjury`} component="div" className="errorTxt" />
                      </Grid>
                      {this.state.isDetails
                        ?
                        <Grid item xs={12} sm={6}>
                          <Typography className="fieldText">Enter Details</Typography>
                          <Field
                            name={`injurerds.${index}.details`}
                            as={TextField}
                            placeholder="BU4"
                            className="inputField"
                            fullWidth
                          />
                          <ErrorMessage name={`injurerds.${index}.details`} component="div" className="errorTxt" />
                        </Grid> :
                        <Grid item xs={12} sm={6}></Grid>
                      }

                      <Grid item xs={12} sm={6}>
                        <Typography className="fieldText">Expected Date of Return</Typography>
                        <DatePicker
                          minDate={new Date()}
                          data-test-id="expectedDateOfReturnTextId"
                          className="inputFieldDate"
                          dateFormat="dd/MM/yyyy"
                          name={`injurerds.${index}.expectedDateOfReturn`}
                          placeholderText="Select Expected Date of Return"
                          selected={injured.expectedDateOfReturn}
                          onChange={(date) =>
                            setFieldValue(`injurerds.${index}.expectedDateOfReturn`, date)
                          }
                          customInput={
                            <TextField
                              fullWidth
                              value={injured.expectedDateOfReturn ? injured.expectedDateOfReturn : ""}
                              placeholder="Select Expected Date of Return"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img
                                      src={IMG_CONST.calendarIcon}
                                      alt="Calendar Icon"
                                      style={{ cursor: "pointer", width: 24, height: 24 }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          }
                        />
                        <ErrorMessage name={`injurerds.${index}.expectedDateOfReturn`} component="div" className="errorTxt" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Typography className="fieldText">Fitness Certificate</Typography>
                        {this.renderFitnessCertificate(index)}
                      </Grid>


              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Button className="addBtn" startIcon={<img src={IMG_CONST.addIcon} />} onClick={() =>
                          push({
                            briefDescription: "",
                            injuredPerson: "",
                            injured: false,
                            employeeId: "",
                            joiningDate: "",
                            contractorName: "",
                            partOfBodyInjured: "",
                            typeOfInjury: "",
                            expectedDateOfReturn: null,
                            eyewitness: "",
                            details: "",
                          })
                        } data-test-id="addInjuredPersonButtonTestId">
                          Add another Injured Person
                        </Button>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                          <Typography
                            data-test-id="eyeWitness"
                            className="fieldText"
                            style={{
                              maxWidth: "150px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              height: "24px",
                              display: "block"
                            }}
                          >
                            Eyewitness(es)
                          </Typography>
                          {this.renderEyewitness(injured.eyewitness, (field, value) =>
                            setFieldValue(`injurerds.${index}.${field}`, value)
                          )}
                          <ErrorMessage name={`injurerds.${index}.eyewitness`} component="div" className="errorTxt" />
                        </Grid>

                        <Grid
                          item xs={12} sm={6} md={6} lg={6} xl={6}
                          style={{ display: "flex", alignItems: "center", padding: "5px" }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              gap: "10px",
                              background: "#fff",
                              alignItems: "center",
                              maxHeight: "100px",
                              overflowY: "auto",
                              padding: "5px"
                            }}
                          >
                            {this.state.selectedEyewitnesses.map((item, index) => (
                              <Box
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  backgroundColor: "#8735E1",
                                  padding: "5px 10px",
                                  borderRadius: "5px"
                                }}
                              >
                                <Typography
                                  style={{
                                    maxWidth: "100px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item}
                                </Typography>
                                <img
                                  src={IMG_CONST.closeIcon}
                                  alt="Remove"
                                  style={{ cursor: "pointer", width: "16px", height: "16px" }}
                                  onClick={() => this.handleRemoveEyewitness(item)}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Grid>
                      </Grid>


                    </Grid>
                  ))}
                </>
              )}
            </FieldArray>
            {this.renderNavigation()}
          </Form>
        )}
      </Formik>

    );
  };


  customInputDate = (
    value: Date | null | undefined,
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onChange: (field: string, value: string) => void,
  ) => {
    return (
      <Input
        placeholder="aa"
        value={value}
        onClick={onClick}
        fullWidth={true}
        disableUnderline
        data-test-id="textInputTest"
        name="dateTest"
        onChange={(event) => onChange("joiningDate", event.target.value)}
        defaultValue={""}
        endAdornment={
          <div>
            <InputAdornment position="end">
              <img src="" className="calenderLogoStyle" />
            </InputAdornment>
          </div>
        }
      />
    );
  };

  renderD1Form = () => {
    return (
      <D1Form>
        <Typography className="headingTxt">D1: CFT Formation</Typography>
  
        <Formik
              enableReinitialize={true}

        data-test-id="d1FormTestId"
          initialValues={ this.state.d1FormValue}
          validationSchema={this.formD1ValidationSchema}
          onSubmit={this.handled1Submit}
        >
          {({ values, setFieldValue }) => (
            <Form
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
              className="formStyle" translate={undefined}            >
              <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <FieldArray name="teamMembers" data-test-id="fieldArrayTestId">
                  {({ push, remove }) => (
                    <>
                      {values.teamMembers.map((_, index) => (
                        <Grid container spacing={2} key={index} alignItems="center" style={{ marginBottom: "24px" }}>
                          <Grid item xs={12}>
                            <Typography className="fieldText">{`Team Member ${index + 1}`}</Typography>
                            <Field
                              name={`teamMembers[${index}].teamMember`}
                              as={Select}
                              IconComponent={StyledDropdownIcon}
                              displayEmpty
                              fullWidth
                              className="inputFieldDropDown"
                              onChange={(event: SelectEventType) =>
                                setFieldValue(`teamMembers[${index}].teamMember`, event.target.value)
                              }
                              MenuProps={{
                                disableScrollLock: true,
                                anchorOrigin: { horizontal: "left", vertical: "bottom" },
                                transformOrigin: { vertical: "top", horizontal: "left" },
                                getContentAnchorEl: null,
                                PaperProps: {
                                  style: {
                                    maxHeight: 300,
                                    overflowY: "auto",
                                  },
                                },
                              }}
                              renderValue={(selected: SelectEventType) =>
                                selected ? selected : (
                                  <div style={webStyle.selectPlaceholder as React.CSSProperties}>
                                    Select Team Member
                                  </div>
                                )
                              }
                            >
                              <CustomMenuItem value="" disabled>
                                Select Team Member
                              </CustomMenuItem>

                              {this.state.leaderShipdata.map((member) => (
                                <CustomMenuItem  value={member.attributes.full_name}>
                                  {member.attributes.full_name}
                                </CustomMenuItem>
                              ))}
                            </Field>

                            <ErrorMessage name={`teamMembers[${index}].teamMember`} component="div" className="errorTxt" />
                          </Grid>
  
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <Typography className="fieldText">Location</Typography>
                              <TextField
                                placeholder={this.state.locationV}
                                className="inputField disaledClass"
                                fullWidth
                                disabled
                              />
                            </Box>
                          </Grid>
  
                          <Grid item xs={12} sm={6}>
                            <Box>
                              <Typography className="fieldText">Function / Area / BU / Department</Typography>
                              <TextField
                                placeholder={this.state.functionValueId}
                                className="inputField disaledClass"
                                fullWidth
                                disabled
                              />
                            </Box>
                          </Grid>
  
                          {index > 0 && (
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                              <Button
                                className="removeBtn"
                                onClick={() => remove(index)}
                                startIcon={<DeleteIcon style={{ color: "red" }} />}
                              >
                                Remove
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
  
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Button className="addBtn" onClick={() => push({ teamMember: "" })}>
                          Add another Team Member
                        </Button>
                      </Grid>
                    </>
                  )}
                </FieldArray>
              </Grid>
  
              {this.renderNavigation()}
            </Form>
          )}
        </Formik>
      </D1Form>
    );
  };
  
  renderD1PartTwoForm = () => {
    return (
      <D1Form>
        <Typography className="headingTxt">D1: CFT Formation</Typography>
  
        <Formik
              enableReinitialize={true}

          initialValues={this.state.d1PartTwoFormValue}
          validationSchema={this.formD1PartTwoValidationSchema}
          onSubmit={this.handled1PartTwoSubmit}
          data-test-id ="dtwoForm"
        >
          {({ values, setFieldValue }) => (
            <Form
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
              className="formStyle"
              translate={undefined}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <Typography className="fieldText">CFT Leader</Typography>
                  <Field
                  data-test-id="leaderTestId"
                    name="leader"
                    as={Select}
                    IconComponent={StyledDropdownIcon}
                    displayEmpty
                    fullWidth
                    className="inputFieldDropDown"
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      setFieldValue("leader", event.target.value)
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: { horizontal: "left", vertical: "bottom" },
                      transformOrigin: { vertical: "top", horizontal: "left" },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          overflowY: "auto",
                        },
                      },
                    }}
                    renderValue={
                      values.leader
                        ? undefined
                        : () => (
                            <div style={webStyle?.selectPlaceholder as React.CSSProperties}>
                              Select Leader
                            </div>
                          )
                    }
                  >
                    <CustomMenuItem value="" disabled>
                      Select Leader
                    </CustomMenuItem>
                    {this.state.leaderShipdata.map((member) => (
                      <CustomMenuItem value={member.attributes.full_name}>
                        {member.attributes.full_name}
                      </CustomMenuItem>
                    ))}
                  </Field>
                  <ErrorMessage name="leader" component="div" className="errorTxt" />
                </Grid>
  
                <Grid item xs={12}>
                  <Typography className="fieldText">Expert (Optional)</Typography>
                  <Field
                  data-test-id="expertTestid"
                    name="expert"
                    as={Select}
                    IconComponent={StyledDropdownIcon}
                    className="inputFieldDropDown"
                    displayEmpty
                    fullWidth
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                      setFieldValue("expert", event.target.value)
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      getContentAnchorEl: null,
                      anchorOrigin: { horizontal: "left", vertical: "bottom" },
                      transformOrigin: { vertical: "top", horizontal: "left" },
                      PaperProps: {
                        style: {
                          overflowY: "auto",
                          maxHeight: 300,
                        },
                      },
                    }}
                    renderValue={
                      values.expert
                        ? undefined
                        : () => (
                            <div style={webStyle?.selectPlaceholder as React.CSSProperties}>
                              Select Expert
                            </div>
                          )
                    }
                  >
                    <CustomMenuItem value="" disabled>
                      Select Expert
                    </CustomMenuItem>
                    {this.state.accountData?.map((account, index) => (
                      <CustomMenuItem key={index} value={account.id}>
                        {account.full_name}
                      </CustomMenuItem>
                    ))}
                  </Field>
                  <ErrorMessage name="expert" component="div" className="errorTxt" />
                </Grid>
              </Grid>
  
              <Grid item xs={12} style={{ marginTop: "10px", display: "flex", alignItems: "flex-end" }}>
                <StyledFormControlLabel
                  control={
                    <CustomCheckbox
                      data-test-id="checkboxTestId"
                      checked={Boolean(values.acknowledge)}
                      onChange={(event) => setFieldValue("acknowledge", event.target.checked)}
                      name="acknowledge"
                      color="primary"
                      icon={<CustomIcon />} />
                  }
                  label="I acknowledge that the report of the incident will be shared with all the members of my plant as well as with Senior Leadership."
                />
                <ErrorMessage name="acknowledge" component="div" className="errorTxt" />
              </Grid>
  
              {this.renderNavigation()}
            </Form>
          )}
        </Formik>
      </D1Form>
    );
  };
  
  

  renderNavigation = () => {
    const { incidentStep } = this.state;
     return (
      <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "40px",}}>
        {incidentStep > 1 && incidentStep < 5 && (
          <Button className="prevBtn" onClick={this.handlePrevious}>
            Previous
          </Button>
        )}
        <Button className="nextBtn" type="submit">
          {incidentStep < 5 ? "Next" : "Submit"}
        </Button>
      </Box>
    )
  }
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box >
        {this.incidentModal()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const IncidentModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "clotherRegular",
    "& .formStyle": {
      height: "650px",
      padding: '0px 25px',
      overflowX: "auto",
      maxHeight: "70vh",
    overflowY: "auto",
  },
  "& .incidentBox": {
    width: "100%",
    maxWidth: "750px",
     backgroundColor: "#FFF",
    borderRadius: "8px",
    maxHeight: "90vh",
    height: "auto",
    fontFamily: "clotherRegular",
  },

  "@media (max-width: 768px)": {
    "& .incidentBox": {
      width: "90%",
     },
    "& .headingPost": {
      fontSize: "18px",
    },
    "& .nextBtn, & .prevBtn, & .addBtn": {
      width: "100%",
    },
  },
  "& .nextBtn": {
    boxSizing: "border-box",
    textTransform: "capitalize",
    color: "#FFFF",
    backgroundColor: "#8735E1",
    border: "5px",
    width: "150px",
    marginLeft: "auto",
    fontFamily: "clotherRegular",
  },
  "& .prevBtn": {
    boxSizing: "border-box",
    textTransform: "capitalize",
    color: "#8735E1",
    backgroundColor: "#FFF",
    border: "1px solid #8735E1",
    width: "150px",
    fontFamily: "clotherRegular",
  },

  "& .addBtn": {
    boxSizing: "border-box",
    textTransform: "capitalize",
    color: "#8735E1",
    backgroundColor: "#FFF",
    border: "1px solid #8735E1",
    width: "100%",
    fontFamily: "clotherRegular",
    height: '45px'
  },
  "& .MuiOutlinedInput-input": { padding: "0px", border: '0px solid transparent' },
  "& .inputField": {
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "45px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    fontSize: "14px",
    fontFamily: "clotherRegular",
    fontWeight: "bold",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none",
      },
      "&::before": {
        content: "none",
      },
      "&::after": {
        content: "none",
      },
    },
  },
  "& .inputFieldDate": {
    width: "133%",
    border: "1px solid #CBD5E1",
    justifyContent: "center",
    borderRadius: "8px",
    margin: "5px 0px",
    display: "flex",
    padding: "0px 10px",
    height: "45px",
    boxSizing: "border-box",
    fontSize: "14px",
    fontFamily: "clotherRegular",
    fontWeight: "bold",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none",
      },
      "&::before": {
        content: "none",
      },
      "&::after": {
        content: "none",
      },
    },
  },
  "& .headingPost": {
    borderRadius: "8px",
    height: "45px",
    fontSize: "22px",
    fontWeight: "bold",
    fontFamily: "clotherRegular",
  },


  "& .inputFieldDropDown": {
    cursor:"pointer",
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    fontFamily: "clotherRegular",
    "& .MuiSelect-icon": {
      color: "#DCDCDC",
      right: "5px",
    },
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none",
      },
      "&::before": {
        content: "none",
      },
      "&::after": {
        content: "none",
      },
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red",
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red",
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red",
    },
    "&[class*='MuiInput-underline']": {
      "&::before": {
        borderBottom: "none",
      },
      "&:hover::before": {
        borderBottom: "none !important",
      },
      "&::after": {
        borderBottom: "none !important",
      }
    },
    "& [class*=MuiSelect-select]": {
      fontFamily: "ClotherRegular",
      fontSize: "16px",
      fontWeight: 700,
      color: "#2B2A29",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .fieldText": {
    fontFamily: "clotherRegular",
    color: "#2B2A29",
    fontSize: "15px"
  },
  "& .errorTxt": {
    color: "red",
    fontFamily: "clotherRegular",
  },
  "& .disaledClass": {
    backgroundColor: "#DCDCDC",
    fontFamily: "clotherRegular",
  },
  "& .datePicker": {
    backgroundColor: "red",
    width: "337px",

  },

});

const CustomMenuItem = styled(MenuItem)({
  "&:hover": {
    backgroundColor: "#F9F3FF",
    color: "#8735E1",
  },
  "&[class*=.MuiMenu-paper]": {
    maxHeight: "300px !important",
  },
});

const D1Form = styled(Box)({

  "& .formD1Style":{
    backgroundColor:'red',
     
  },
  "& .headingTxt": {
    color: '#2B2A29',
    backgroundColor: "#FAF3FD",
    fontWeight: "bold",
    fontFamily: "clotherRegular",
    padding: "10px 20px",
    fontSize: "17px"
  }
})

const InputWrapper = styled("div")({
  position: "relative",
  width: "100%",
});

const TimeInput = styled("input")({
  width: "100%",
  padding: "10px 40px 10px 10px", // Space for the icon
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "40px",
  fontSize: "16px",
  appearance: "none", // ✅ Removes browser styles
  "-webkit-appearance": "none", // ✅ Safari Fix
  "-moz-appearance": "textfield", // ✅ Firefox Fix
});

const Icon = styled(TimerIcon)({
  position: "absolute",
  fontSize: "20px",
  right: "10px",
  top: "50%",
  transform: "translateY(-50%)",
  color: "#DADADA",
  cursor: "pointer",
  pointerEvents: "none",
});

const CustomCheckbox = styled(Checkbox)({
  color: "#828282",
  "&.Mui-checked": {
    color: "#8735E1",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "24px",
  },
});

const CustomIcon = styled(CheckBoxOutlineBlankIcon)({
  fontSize: "24px",
  boxSizing: "border-box",
  border: "1px solid #828282",
  borderRadius: "4px",
  backgroundColor: "white",
  color: "transparent",
});

const StyledFormControlLabel = styled(FormControlLabel)({
  color: "#828282",
  fontSize: "16px",
  fontWeight: 400,
  fontFamily: "ClotherRegular !important",
  "& .MuiTypography-body1": {
    color: "#828282",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "ClotherRegular !important",
  }
});
// Customizable Area End
