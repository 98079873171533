// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.investApiContentType="multipart/form-data"
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DashboardGuests";
exports.labelBodyText = "DashboardGuests Body";
exports.phoneInputRegex=/^\d{10}$/
exports.btnExampleTitle = "CLICK ME";
exports.getVerificationOtp="bx_block_login/logins/send_login_otp";
exports.validateOtpApiMethod="PUT";
exports.validateOtpApiEndpoint="bx_block_login/logins/otp_login";
exports.getCompainesApiEnpoint="bx_block_dashboardguests/companies";
exports.investApiEndpoint="bx_block_dashboardguests/dashboard_guests";
exports.getPortfolioApiEndPoint="bx_block_dashboardguests/portfolio";
exports.assignToAPiEndPoint="/bx_block_dashboardguests/safety_interactions";
exports.startInteractionApiEndPoint="/bx_block_dashboardguests/safety_interactions";
exports.locationEndPoint = "/bx_block_profile/profiles/get_all_sub_functions";
exports.activeUsersEndpoint = "/bx_block_posts/active_accounts";
exports.assignToItemAPiEndPoint="/bx_block_dashboardguests/safety_interactions/";
exports.getProfileEndPoint="account_block/accounts/";
exports.portfolioPublicUrl="anupam-265950-react.b265950.dev.eastus.az.svc.builder."

exports.observationCategoriesData = [
  {
    section: "S1: Reaction of People",
    form_name: "reaction_of_people",
    items: [
      { id: 1, name: "Adjusting PPE’s", status: "Safe", positive_action: true },
      { id: 2, name: "Leaving the work site as you enter", status: "Safe", positive_action: true },
      { id: 3, name: "Others", status: "Safe", positive_action: false },
      { id: 4, name: "Stopping or Changing work", status: "At Risk", positive_action: false },
      { id: 5, name: "Switching to Correct Tool", status: "Safe", positive_action: false },
      { id: 6, name: "Uneasiness", status: "Safe", positive_action: false },
    ],
  },
  {
    section: "S2: Position of People",
    form_name: "position_of_people",
    items: [
      { id: 7, name: "Absorbing", status: "Safe", positive_action: true },
      { id: 8, name: "Caught Between", status: "Safe", positive_action: false },
      { id: 9, name: "Electrical Current", status: "Safe", positive_action: false },
      { id: 10, name: "Falling", status: "At Risk", positive_action: false },
      { id: 11, name: "Inhaling", status: "Safe", positive_action: false },
      { id: 12, name: "Lighting", status: "Safe", positive_action: false },
      { id: 13, name: "Noise", status: "Safe", positive_action: false },
      { id: 14, name: "Posture", status: "Safe", positive_action: false },
      { id: 15, name: "Striking Against", status: "Safe", positive_action: false },
      { id: 16, name: "Struck by", status: "Safe", positive_action: false },
      { id: 17, name: "Swallowing", status: "Safe", positive_action: false },
      { id: 18, name: "Temperature Extremes", status: "Safe", positive_action: false },
      { id: 19, name: "Tools & Grips", status: "Safe", positive_action: false },
      { id: 20, name: "Type & Number of Motions", status: "Safe", positive_action: false },
      { id: 21, name: "Vibration", status: "Safe", positive_action: false },
      { id: 22, name: "Work Area Design", status: "Safe", positive_action: false },
      { id: 23, name: "Others", status: "Safe", positive_action: false },
    ],
  },
  {
      section: "S3: Personal  Protective Equipment",
      form_name: "personal_protective_equipment",
      items: [
        { id: 24, name: "Face Mask", status: "Safe", positive_action: true },
        { id: 25, name: "Ears", status: "Safe", positive_action: false },
        { id: 26, name: "Eyes & Face", status: "Safe", positive_action: false },
        { id: 27, name: "Feet & Legs", status: "At Risk", positive_action: false },
        { id: 28, name: "Hands & Arms", status: "Safe", positive_action: false },
        { id: 29, name: "Head", status: "Safe", positive_action: false },
        { id: 30, name: "Respiratory", status: "Safe", positive_action: false },
        { id: 31, name: "Right for Job", status: "Safe", positive_action: false },
        { id: 32, name: "Trunk", status: "Safe", positive_action: false },
      ],
    },
    {
      section: "S4: Tools & Equipment",
      form_name: "tools_and_equipment",
      items: [
        { id: 33, name: "In Safe Condition", status: "Safe", positive_action: true },
        { id: 34, name: "Bad Condition", status: "Safe", positive_action: false },
        { id: 35, name: "Right for Job", status: "Safe", positive_action: false },
        { id: 36, name: "Not suitable for job", status: "At Risk", positive_action: false },
        { id: 37, name: "Used Correctly", status: "Safe", positive_action: false },
        { id: 38, name: "Not used Correctly", status: "Safe", positive_action: false },
      ],
    },
    {
      section: "S5: Procedure",
      form_name: "procedure",
      items: [
        { id: 39, name: "Adequate for job", status: "Safe", positive_action: true },
        { id: 40, name: "Not Adequate for Job", status: "Safe", positive_action: false },
        { id: 41, name: "Being Followed", status: "Safe", positive_action: false },
        { id: 42, name: "Not being Followed", status: "At Risk", positive_action: false },
        { id: 43, name: "Existing and Updated", status: "Safe", positive_action: false },
        { id: 44, name: "Not Existing", status: "Safe", positive_action: false },
      ],
    },
    {
      section: "S6: House Keeping",
      form_name: "house_keeping",
      items: [
        { id: 45, name: "Materials & tools kept safely", status: "Safe", positive_action: true },
        { id: 46, name: "Materials & tools not kept safely", status: "Safe", positive_action: false },
        { id: 47, name: "Workplace Orderly", status: "Safe", positive_action: false },
        { id: 48, name: "Workplace Disorderly", status: "At Risk", positive_action: false },
      ],
    },
];

exports.safetyInteractionByIdApiEndPoint= "bx_block_dashboardguests/safety_interactions"
// Customizable Area End
