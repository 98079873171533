import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("../config");

interface IObservationCategoryItem {
  id: number,
  name: string;
  status: string;
  positive_action: boolean;
};

interface IObservationCategory {
  section: string;
  form_name: string;
  items: IObservationCategoryItem[];
};
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  observationCategoriesData: IObservationCategory[];
  // Customizable Area End
}
interface SS {}

export default class SafetyInteractionSelectionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      observationCategoriesData: configJSON.observationCategoriesData,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
   
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleCheckboxChange = (categoryIndex: number, itemIndex: number) => {
    this.setState((prevState) => {
      const updatedObservations = prevState.observationCategoriesData.map((category, cIndex) =>
        cIndex === categoryIndex
          ? {
              ...category,
              items: category.items.map((item, iIndex) =>
                iIndex === itemIndex ? { ...item, positive_action: !item.positive_action } : item
              )
            }
          : category
      );

      return { observationCategoriesData: updatedObservations };
    });
  }

  handleRadioChange = (categoryIndex: number, itemIndex: number, value: string) => {
    this.setState((prevState) => {
      const updatedObservations = [...prevState.observationCategoriesData];
      updatedObservations[categoryIndex] = {
        ...updatedObservations[categoryIndex],
        items: updatedObservations[categoryIndex].items.map((item, iIndex) =>
          iIndex === itemIndex ? { ...item, status: value } : item
        )
      };
      return { observationCategoriesData: updatedObservations };
    });
  };
  // Customizable Area End
}

