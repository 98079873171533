import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    IconButton,
    TextField,
    ThemeProvider,
    Typography,
    styled,
    Select,
     TableBody, TableCell, TableRow,
    MenuItem as CustomMenuItem
} from "@material-ui/core";
import moment from "moment";
import { MoreVert, KeyboardArrowDown } from "@material-ui/icons";

import { Field, FieldArray, Form, Formik } from "formik";
import { deleteIcon, calendarIcon, saveIcon } from "./assets";
import { theme } from "./RootCauseInvestigation.web";
import DatePicker from "react-datepicker"
import PreventOccurrenceController, {IPreventOccurrence, Props} from "./PreventOccurrenceController.web";


// Customizable Area End

export default class PreventOccurrence extends PreventOccurrenceController{
    
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    customField = ({ name, placeholder, multiline = false, maxRows, minRows, className = "textarea" }: IPreventOccurrence) => (
        <Grid item xs={12} sm={6}>
            <Field
                as={TextField}
                name={name}
                placeholder={placeholder}
                fullWidth
                multiline={multiline}
                maxRows={maxRows}
                minRows={minRows}
                InputProps={{ disableUnderline: true }}
                className={className}
            />
        </Grid>
    );

    customDatePicker = ({ name, selectedDate, onChange, placeholderText, className = "inputDateField" }: { name: string; selectedDate: Date | null; onChange: (date : Date) => void; placeholderText: string; className?: string }) => (
        <Grid item xs={12} sm={6}>
            <div className="datePickerWrapper">
                <DatePicker
                    selected={selectedDate}
                    onChange={onChange}
                    name = {name}
                    placeholderText={placeholderText}
                    dateFormat="dd/MM/yyyy"
                    className={className}
                    minDate={new Date()}
                />
                <img
                    src={calendarIcon}
                    alt="Calendar Icon"
                    className="calendarIcon"
                    onClick={(e) => {
                        e.preventDefault();
                        const wrapperDiv = e.currentTarget.closest(".datePickerWrapper");
                        if(wrapperDiv){
                            const inputField = wrapperDiv.querySelector(`.${className}`) as HTMLInputElement;
                            if (inputField) {
                                inputField.focus();
                            }
                        }
                    }}
                />
            </div>
        </Grid>
    );
   
  
    renderDSevenThirdForm = () => {
        return (
          <Formik
            enableReinitialize
            data-test-id="dSevenThirdFormTestId"
            initialValues={this.getInitialValues()}
            onSubmit={this.handleSubmit}
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <Form className="formStyle" style={{ display: "flex", flexDirection: "column" }} translate={undefined}>
                {this.renderTypography("HIRA / SFMEA")}
                <Grid container spacing={2}>
                  {this.renderCommonFields(values, setFieldValue)}
                </Grid>
                
                {this.renderTypography("Others")}
                <FieldArray name="others">
                  {({ push, remove }) => (
                    <>
                      {values.others?.map((item: any, index: number) => this.renderOtherFields(item, index, values, setFieldValue, remove))}
                      {this.renderAddButton(push)}
                    </>
                  )}
                </FieldArray>
      
                {this.renderActionButtons(handleSubmit)}
              </Form>
            )}
          </Formik>
        );
      };
      
      getInitialValues = () => {
        return {
          documentDate: this.state.documentDate || null,
          documentName: this.state.documentName || "",
          documentNumber: this.state.documentNumber || "",
          documentRemark: this.state.documentRemark || "",
          documentVersion: this.state.documentVersion || "",
          others: Array.isArray(this.state.others) && this.state.others.length
            ? this.state.others
            : [{
                id: "",
                documentFor: "",
                documentName: "",
                documentNumber: "",
                version: "",
                date: null,
                remark: "",
              }],
        };
      };
      
      handleSubmit = (values: any) => {
        this.addD7PostCreationDetailsAPICall(values, false);
      };
      
      renderTypography = (text: string) => {
        return <Typography className="fieldText">{text}</Typography>;
      };
      
      renderCommonFields = (values: any, setFieldValue: any) => {
        return (
          <>
            {this.customField({
              name: "documentName",
              placeholder: "Document Name",
              maxRows: 1,
              minRows: 1,
            })}
            {this.customField({
              name: "documentNumber",
              placeholder: "Document Number",
              maxRows: 1,
              minRows: 1,
            })}
            {this.customField({
              name: "documentVersion",
              placeholder: "Version",
              maxRows: 1,
              minRows: 1,
            })}
            {this.customDatePicker({
              name: "documentDate",
              selectedDate: values.documentDate ? new Date(values.documentDate) : null,
              onChange: (date: any) => setFieldValue("documentDate", date ? date.toString() : null),
              placeholderText: "Date",
            })}
            {this.customField({
              name: "documentRemark",
              placeholder: "Remark",
              multiline: true,
              maxRows: 3,
              minRows: 3,
              className: "textareaStyle",
            })}
          </>
        );
      };
      
      renderOtherFields = (item: any, index: number, values: any, setFieldValue: any, remove: any) => {
        return (
          <React.Fragment key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ minWidth: "204%", marginTop: "10px" }}>
                {this.customField({
                  name: `others.${index}.documentFor`,
                  placeholder: "Document is for",
                  maxRows: 1,
                  minRows: 1,
                })}
              </Grid>
      
              {this.renderOtherFieldGroup(index, values, setFieldValue)}
              
              {index > 0 && (
                <Grid item xs={12} className="deleteIcon">
                  <IconButton onClick={() => remove(index)} data-test-id="removeButton">
                    <img src={deleteIcon} alt="Delete Icon" height={20} width={20} />
                    Remove
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </React.Fragment>
        );
      };
      
      renderOtherFieldGroup = (index: number, values: any, setFieldValue: any) => {
        return (
          <>
            {this.customField({
              name: `others.${index}.documentName`,
              placeholder: "Document Name",
              maxRows: 1,
              minRows: 1,
            })}
            {this.customField({
              name: `others.${index}.documentNumber`,
              placeholder: "Document Number",
              maxRows: 1,
              minRows: 1,
            })}
            {this.customField({
              name: `others.${index}.version`,
              placeholder: "Version",
              maxRows: 1,
              minRows: 1,
            })}
            {this.customDatePicker({
              name: `others.${index}.date`,
              selectedDate: this.state.documentDate ? new Date(this.state.documentDate) : null,
              onChange: (date: any) => setFieldValue(`others.${index}.date`, date ? date.toISOString() : null),
              placeholderText: "Date",
            })}
            {this.customField({
              name: `others.${index}.remark`,
              placeholder: "Remark",
              multiline: true,
              maxRows: 3,
              minRows: 3,
              className: "textareaStyle",
            })}
          </>
        );
      };
      
      renderAddButton = (push: any) => {
        return (
          <Grid item xs={12}>
            <Button
              className="addCauseButton"
              data-test-id="pushOthers"
              onClick={() =>
                push({
                  id: "",
                  documentFor: "",
                  documentName: "",
                  documentNumber: "",
                  version: "",
                  date: null,
                  remark: "",
                })
              }
            >
              + Add another Implement / Verify Action
            </Button>
          </Grid>
        );
      };
      
      renderActionButtons = (handleSubmit: any) => {
        return this.actionButtons({
          onPrevious: () => this.props.handlePrev(),
          onSave: () => {
            handleSubmit();
            this.setState({ isSaved: true });
          },
          onNext: () => {
            this.handleNext();
            this.setState({ isSaved: false });
          },
        });
      };
      
      
    renderDSevenFourthForm = () => {
        return (
          <Formik
          enableReinitialize
            data-test-id="dSevenFourthFormTestId"
            initialValues={{
                trainedSelectedMemberList: this.state.trainedSelectedMemberList?.length
                ? this.state.trainedSelectedMemberList
                : [{ name: "", id: 0 }, { name: "", id: 0 }, { name: "", id: 0 }],
              feasibility: this.state.feasibility || "",
              riskAssessment: this.state.riskAssessment ? "Yes" : "No",
              sopWi: this.state.sopWi ? "Yes" : "No",
              absoluteVoilation: this.state.absoluteVoilation ? "Yes" : "No",
            }}
            onSubmit={(values) => {
                this.addD7PostCreationDetailsAPICall(values, false);
            }}
          >
            {({ values, setFieldValue, handleSubmit }) => (
                
              <Form className="formStyle" translate={undefined}>
                <Typography variant="h6" className="fieldText">
                  Trained Members
                </Typography>
                <FieldArray name="trainedSelectedMemberList">
                  {({ push, remove }) => (
                    <>
                      <Grid container spacing={2}>
                      {values.trainedSelectedMemberList?.map((item, index) => (
                        <Grid item xs={6} key={index}>
                            {this.renderDropdownField(
                            `trainedSelectedMemberList[${index}].id`, 
                            `Member ${index + 1}`,
                            this.state.membersList.map((member) => ({
                                value: member.id, 
                                label: member.full_name ?? "Unknown", 
                            })),
                            (selectedId) => {
                                const selectedMember = this.state.membersList.find(member => member.id === selectedId);
                                setFieldValue(`trainedSelectedMemberList[${index}]`, {
                                id: selectedId,
                                name: selectedMember?.full_name || "", 
                                });
                            }
                            )}
                        </Grid>
                        ))}
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: "10px" }}>
                        <Button
                          onClick={() => push({ name: "", id: 0 })}
                          className="addCauseButton"
                        >
                          + Add Another Member
                        </Button>
                      </Grid>
                    </>
                  )}
                </FieldArray>


                <Typography variant="h6" className="fieldText">
                  Feasibility of Horizontal Deployment
                </Typography>

                <Grid item xs={12}>
                  {this.customField({
                    name: "feasibility",
                    placeholder: "Brief Description",
                    multiline: true,
                    maxRows: 4,
                    minRows: 4,
                    className: "textAreaStyle",
                  })}
                </Grid>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" className="fieldText">
                    Risk assessment required to update
                  </Typography>
                  {this.renderDropdownField(
                    `Risk assessment required to update`,
                    `Select Option`,
                    [
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                     
                    ]
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography variant="h6" className="fieldText">
                    SOP/WI required to update
                  </Typography>
                  {this.renderDropdownField(
                    `SOP/WI required to update`,
                    `Select Option`,
                    [
                        { value: "Yes", label: "Yes" },
                        { value: "No", label: "No" },
                       
                    ]
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                <Typography variant="h6" className="fieldText">
                Is it safety absolute violation
                  </Typography>
                  {this.renderDropdownField(
                    `Is it safety absolute violation`,
                    `Select Option`,
                    [
                        { value: "Yes", label: "Yes" },
                        { value: "No", label: "No" },
                       
                    ]
                  )}
                </Grid>
                </Grid>

                {this.actionButtons({
                  onPrevious: this.handlePrevious,
                  onSave: () => {
                    handleSubmit();
                    this.setState({isSaved: true});
                  },
                  onNext: () => {this.handleNext(); 
                    this.setState({isSaved: false});
                  },
                })}
              </Form>
            )}
          </Formik>
        );
    };



    renderDSevenFifthForm = () => {
        return (
            <Formik
            enableReinitialize
            data-test-id="dSevenFifthFormTestId"
            initialValues={{
              actionDetailsList: this.state.actionDetailsList, 
              newActionDetailsList: {
                assignTo: "",
                assignToID: "",
                functionOrDepartmentID: "",
                functionOrDepartment: "",
                status: "",
                targetDate: null,
              },
            }}
            onSubmit={(values) => {
                this.setState({ actionDetailsList: values.actionDetailsList }, () => {
                    this.addD7PostCreationDetailsAPICall(values, true);
                  });
            }}
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <Form className="formStyle" translate={undefined}>
                <FieldArray name="actionDetailsList">
                  {({ push, remove }) => (
                    <TableBody>
                      {values.actionDetailsList?.map((action, index) => (
                        <TableRow key={index}>
                          <TableCell>{`Action 0${index + 1}`}</TableCell>
                          <TableCell>{action.functionOrDepartment}</TableCell>
                          <TableCell>{action.assignTo}</TableCell>
                          <TableCell>
                            {moment(action.targetDate).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>{action.status}</TableCell>
                          <TableCell align="center">
                            <MoreVert style={{ cursor: "pointer" }} />
                          </TableCell>
                        </TableRow>
                      ))}
      
                      <TableRow>
                        <TableCell>{`Action 0${values.actionDetailsList?.length + 1}`}</TableCell>
                        <TableCell>
                          {this.renderDropdownField(
                            "newActionDetailsList.functionOrDepartment",
                            "Select Location",
                            this.state.buLoactionList.map((option: any) => ({
                              value: option.attributes.id,
                              label: option.attributes.name,
                            }))
                          )}
                        </TableCell>
                        <TableCell>
                          {this.renderDropdownField(
                            "newActionDetailsList.assignTo",
                            "Assign To",
                            this.state.assignToOptions?.map((option) => ({
                              value: option.value,
                              label: option.name,
                            }))
                          )}
                        </TableCell>
                        <TableCell>
                          {this.customDatePicker({
                            name: "newActionDetailsList.targetDate",
                            selectedDate: values.newActionDetailsList?.targetDate || null,
                            onChange: (date) =>
                              setFieldValue("newActionDetailsList.targetDate", date),
                            placeholderText: "Target Date",
                          })}
                        </TableCell>
                        <TableCell>
                          {this.renderDropdownField(
                            "newActionDetailsList.status",
                            "Status",
                            [
                              { value: "Due", label: "Due" },
                              { value: "Overdue", label: "Overdue" },
                              { value: "Completed", label: "Completed" },
                            ]
                          )}
                        </TableCell>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <img
                              src={saveIcon}
                              onClick={() => {
                                push(values.newActionDetailsList); 
                                setFieldValue("newActionDetailsList", {
                                  assignTo: "",
                                  assignToID: "",
                                  functionOrDepartmentID: "",
                                  functionOrDepartment: "",
                                  status: "",
                                  targetDate: null,
                                });
                              }}
                              width={28}
                              height={28}
                              alt="Save"
                            />
                            <img
                              src={deleteIcon}
                              onClick={() => remove(values.actionDetailsList?.length - 1)}
                              width={28}
                              height={28}
                              style={{ marginLeft: "10px" }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </FieldArray>
                {this.actionButtons({
                  onPrevious: this.handlePrevious,
                  onSave: () => {
                    handleSubmit();
                    this.setState({isSaved: true})
                  },
                  onNext: () => { 
                    handleSubmit();
                    this.setState({isSaved: false})
                  },
                })}
              </Form>
            )}
          </Formik>
        );
    };
    
      
    
    renderDropdownField = (
        name: string,
        placeholder: string,
        options: { value: string | number; label: string }[],
        onChange?: (value: string | number | unknown) => void 
    ) => (
        <Field name={name}>
            {({ field, form }: { field: any; form: any }) => {
                const selectedOption = options.find(option => option.value === field.value);
    
                return (
                    <Select
                        {...field}
                        displayEmpty
                        fullWidth
                        className="inputFieldDropDown"
                        onChange={(event) => {
                            const selectedValue = event.target.value;
                            form.setFieldValue(name, selectedValue);
    
                            if (onChange) {
                                onChange(selectedValue);  
                            }
                        }}
                        IconComponent={KeyboardArrowDown}
                        MenuProps={{ disableScrollLock: true }}
                        renderValue={(selected) =>
                            selectedOption ? selectedOption.label : <div style={{ color: "#aaa" }}>{placeholder}</div>
                        }
                    >
                        <CustomMenuItem value="" disabled>
                            {`Select ${placeholder}`}
                        </CustomMenuItem>
                        {options.map((option) => (
                            <CustomMenuItem key={option.value} value={option.value}>
                                {option.label}
                            </CustomMenuItem>
                        ))}
                    </Select>
                );
            }}
        </Field>
    );
    
    

    actionButtons = ({
    onPrevious,
    onSave,
    onNext,
    disablePrevious = false,
    disableNext = false,
}: {
    onPrevious: () => void;
    onSave: () => void;
    onNext: () => void;
    disablePrevious?: boolean;
    disableNext?: boolean;
}) => (
    <Grid item xs={12}>
        <Box className="actionButtons">
            <Box>
                <Button
                    className="prevButton"
                    data-test-id = "prevButton"
                    onClick={onPrevious}
                    disabled={disablePrevious}
                >
                    Previous
                </Button>
            </Box>
            <Box style={{ gap: "20px", display: "flex" }}>
                <Button
                    className="saveButton"
                    data-test-id = "saveButton"
                    onClick={(e) => {
                        e.preventDefault();
                        onSave();
                    }}
                >
                    Save
                </Button>
                <Button
                    className="nextButton"
                    data-test-id = "nextButton"
                    onClick={onNext}
                    disabled={disableNext}
                >
                    Next
                </Button>
            </Box>
        </Box>
    </Grid>
);


renderCurrentStep = () => {
    const { step } = this.state;
        if (step === 1) {
            return this.renderDSevenThirdForm();
        } else  if (step === 2) {
            return this.renderDSevenFourthForm();
        } else if (step === 3) {
            return this.renderDSevenFifthForm();
        }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        console.log("handleNext prop:", this.props.handleNext);
        return (
            <ThemeProvider theme={theme}>
                <Wrapper>
                <Grid item xs={12}>
                    <Typography className="headerText">D7: Prevent Occurrence</Typography>
                </Grid>
                    {this.renderCurrentStep()}
                </Wrapper>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    backgroundColor: "#FFF",
    border: "10px solid rgb(238, 238, 238)",
    padding: "20px",
    "& .inputFieldDropDown": {
        width: "100%",
        fontFamily: "clotherRegular",
        border: "1px solid #DCDCDC",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        padding: "10px",
        margin: "5px 0px",
        boxSizing: "border-box",
        "& .MuiSelect-icon": {
            right: "5px",
            color: "#A1A1A1",
        },
        "& .MuiInput-root": {
            "&:focus": {
                outline: "none",
            },
            "&::after": {
                content: "none",
            },
            "&::before": {
                content: "none",
            },
        },
        "&.MuiInput-underline:after": {
            borderBottom: "0px solid red",
        },
        "&.MuiInput-underline:before": {
            borderBottom: "0px solid red",
        },
        "&:hover:not(.Mui-disabled):after": {
            borderBottom: "0px solid red",
        },
        "&:hover:not(.Mui-disabled):before": {
            borderBottom: "0px solid red",
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: "transparent",
        },
    },
    "& .headerText": {
        margin: "15px 0px",
        color: "#2B2A29",
        fontWeight: 700,
        fontFamily: "clotherRegular",
        fontSize: "22px"
    },
    "& .fieldText": {
        color: "#2B2A29",
        fontWeight: 400,
        fontFamily: "clotherRegular",
        fontSize: "16px",
        lineHeight: "18px",
        marginBottom: "10px",
        marginTop: "20px",
    },
    "& .errorTxt": {
        color: "red"
    },
    "& .headingTxt": {
        color: '#2B2A29',
        backgroundColor: "#FAF3FD",
        fontSize: "17px",
        fontFamily: "clotherRegular",
        padding: "10px 20px"
    },

      "& .deleteIcon": {
        display: "flex",
        justifyContent: "end-start",
        },
      "& .saveIcon": {
        width: "28px",
        height: "28px",
      },
      "& .addCauseButton": {
          padding: "10px 20px",
          textTransform: "none",
          marginTop: "18px",
          color: "#8735E1",
          border: "1px solid #8735E1",
          fontSize: "16px",
          fontFamily: "'ClotherRegular'",
          lineHeight: "22px",
          fontWeight: 700,
      },
      
      "& .actionButtons": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
      },
    "& .prevButton, .saveButton": {
        height: "50px",
        width: "160px",
        border: "1px solid #8735E1",
        borderRadius: "8px",
        textTransform: "none",
        fontFamily: "'ClotherRegular'",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "22px",
        color: "#8735E1",
    },
      "& .nextButton": {
          height: "50px",
          width: "160px",
          fontFamily: "'ClotherRegular'",
          color: "#FFFFFF",
          fontWeight: 700,
          fontSize: "20px",
          backgroundColor: "#8735E1",
          lineHeight: "22px",
          textTransform: "none",
          borderRadius: "8px",
      },
      "& .textareaStyle": {
        padding: '10px',
        borderRadius: '8px',
        border: "1px solid #DCDCDC",
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '18px',
        color: "#DCDCDC",
        width: "197%"
    },
    "& .textArea" : {
        padding: '10px',
        borderRadius: '8px',
        width: "95%",
        border: "1px solid #DCDCDC",
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '18px',
        color: "#DCDCDC"
    },
    "& .datePickerWrapper": {
    position: "relative",
    width: "95%",
    display: "flex",
    minHeight: "31px",
    border: "1px solid #DCDCDC",
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: "#FFF",
    "& .inputDateField": {
        width: "95%",
        border: "none",
        outline: "none",
        fontFamily: "ClotherRegular",
        fontSize: "16px",
        lineHeight: "18px",
        color: "#2B2A29",
    },
    "& .calendarIcon": {
        position: "absolute",
        right: "15px",
        top: "15px",
        cursor: "pointer",
        width: "20px",
        height: "20px",
    },
},

});

const webStyles = {
    listText: {
      fontSize: "20px",
      fontWeight: 700,
      color: "#2B2A29",
      fontFamily: "clotherRegular",
      marginBottom: "5px",
      marginRight: "16px"
    },
    clearAllButton: {
      border: "1px solid #8735E1",
      borderRadius: "8px",
      backgroundColor: "#fff",
      fontWeight: 700,
      fontFamily: "clotherRegular",
      fontSize: "20px",
      color: "#8735E1",
      width:"160px",
      height:"50px"
    },
    applyButton: {
      borderRadius: "8px",
      backgroundColor: "#8735E1",
      fontWeight: 700,
      fontSize: "20px",
      fontFamily: "clotherRegular",
      color: "#fff",
      width:"160px",
      height:"50px",
    },
    countinueButton: {
      borderRadius: "8px",
      backgroundColor: "#8735E1",
      fontWeight: 700,
      fontSize: "20px",
      fontFamily: "clotherRegular",
      color: "#fff",
      height:"50px",
    },
    filterStatusText: {
      fontSize: "1.125rem",
      fontWeight: 400,
      color: "black",
      fontFamily: "clotherRegular",
    },
    filterClearText: {
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#8735E1",
      fontFamily: "clotherRegular",
      cursor:"pointer"
    },
    breadcrumbSeparator: {
      margin: "0 5px",
      fontSize: "14px",
      color: "#DCDCDC",
    },
    incidentTitle: {
      fontSize: "14px",
      color: "#8735E1",
      fontWeight: 700,
      cursor: "pointer",
      fontFamily: "clotherRegular",
    },
    boxModalStyle: {
      backgroundColor: '#fff',
      borderRadius: 8,
      boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
      padding: "25px",
      width: 620,
    },
    modalStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    listingStatusChip: {
      fontSize: "0.75rem",
      fontWeight: 700,
      fontFamily: "clotherRegular",
      padding: "6px 5px"
    },
    listingTitleText: {
      fontSize: "1rem",
      fontWeight: 700,
      color: "#2B2A29",
      fontFamily: "clotherRegular",
    },
    cftLeaderText: {
      fontSize: "1rem",
      fontWeight: 400,
      color: "#A1A1A1",
      fontFamily: "clotherRegular",
    },
    listingBox: {
      borderRadius: "8px",
      border: "1px solid #DCDCDC",
      padding: "1rem",
      backgroundColor: "#fff",
      cursor: 'pointer'
    },
    chipStyle: {
      margin: 4,
      backgroundColor: '#EDEDED',
    },
    chipSelectedStyle: {
      backgroundColor: '#7B61FF',
      color: '#fff',
      margin: 4,
    },
    filterHeaderText: {
      fontSize: "1.5rem",
      fontWeight: 700,
      color: "#2B2A29",
      fontFamily: "clotherRegular",
    },
    headerText: {
      fontSize: "1.625rem",
      fontWeight: 700,
      color: "#2B2A29",
      fontFamily: "clotherRegular",
    },
    breadcrumbText: {
      fontSize: "0.875rem",
      fontWeight: 400,
      color: "#A1A1A1",
      fontFamily: "clotherRegular",
      cursor: "pointer"
    },
    mainBoxListing: {
      padding: "30px"
    },
    tableCellFont: {
      fontFamily: "clotherRegular",
      fontWeight: 700,
      fontSize: "1rem",
      color: "#fff"
    },
    listBoxClickedBox:{
      backgroundColor: "#fff",
      padding: "30px"
    }
  }
// Customizable Area End