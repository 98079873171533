// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { FormikHelpers } from 'formik';
import * as Yup from "yup";
import React from "react";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { IBlock } from "../../../framework/src/IBlock";
import { mic, micStart, } from "./assets"
interface SpeechRecognitionEvent extends Event {
  results: {
    [index: number]: {
      [index: number]: {
        transcript: string;
      };
    };
  };
}

interface SpeechRecognitionError extends Event {
  error: string;
  message: string;
  timeStamp: number;
}

interface ReportData {
    label: string;
    id: string;
}
interface InjuredPerson {
  fitnessCertificate:string;
  briefDescription: string;
  injuredPerson: string;
  injured: boolean;
  employeeId: string;
  joiningDate: string;
  contractorName: string;
  partOfBodyInjured: string;
  typeOfInjury: string;
  details: string;
  expectedDateOfReturn: Date | null;
  eyewitness: string;
}

interface SavedValues {
  injurerds: InjuredPerson[];
}

interface CategoryData {
    label: string;
    id: string;
}

 
interface FunctionData {
  
    "id":string;
    "type":string;
    "attributes": {
        "id": number,
        "created_at":string;
        "updated_at":string;
        "name":string;
    }

}
export interface SelectEventType {
    target: {
        value: string | unknown;
     }
};
export interface LocationData {
    label: string;
    id: string;
};

export interface PersonName {
    label: string;
    id: string;
};

export type UpldFileLink = string | ArrayBuffer | null;

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
};

interface ValidResponseType {
  leadership_user: {
    data: LeaderShipData[];
  };
  accounts: AccountData[];
  iir_code:string;
  injury:[];
  body_part:[];
  shifts:{
    "data": [
        {
            "id": string
            "type": string
            "attributes": {
                "id": 9,
                "name": string
            }
        }]
      }
  site_location:string;
  sub_functions: {
    data: Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        created_at: string;
        updated_at: string;
        name: string;
      };
    }>;
  };
  message: string;
  data: Record<string, unknown>;
  meta: {
    message: string;
  };
}

interface InvalidResponseType {
  errors: string;
};
interface ShiftData {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
    };
  }[];
}

interface AccountData {
  id: number;
  activated: boolean;
  email: string;
  user_name: string | null;
  first_name: string | null;
  last_name: string | null;
  category_id: number | null;
  full_name: string;
  gender: string;
  date_of_birth: string;
  country_code: number;
  phone_number: number;
  full_phone_number: string;
  sub_category_id: number | null;
  role_id: number;
  official_email: string;
  employee_id: string;
  employee_type_id: number;
  is_profile_completed: boolean;
  profile_location_id: number | null;
  country_id: number | null;
  work_location_id: number | null;
  is_safety_incharge: boolean;
  level: number | null;
  deactivated: boolean;
  points: number;
  platform: string | null;
  supervisor_id: number | null;
}

interface BodyPart {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}


interface InjuryData {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface LeaderShipData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    full_name: string;
  };
}
interface FormOneData {
  employeeId: string;
  iirNo: string;
  report: string;
  site: string;
  department: string;
  photo: string;
  uploadedImages: string;
  description: string;
  function: string;
}

interface FormTwoData {
  shift: string;
  time: string;
  locations: string;
}

interface D1PartTwoFormValue {
  leader: string;
  expert: string;
  acknowledge: boolean;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    isIncident:boolean
    onModalClick?: () => void
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
    // Customizable Area Start
    spokenText: string;
    isLoader:boolean;
    personName:PersonName[]
    isIncident: boolean;
    incidentStep: number;
    isDetails:boolean;
    reportData: ReportData[]
    locationData:LocationData[]
    categoryData:CategoryData[]
    siteData: string;
    shiftData:ShiftData;
    iirNo:string;
    accountData:Array<AccountData>;
    bodyPart:Array<BodyPart>;
    injuryData:Array<InjuryData>
    functionData:Array<FunctionData>;
    inicidentRelateImages: string[];
     upldFileLink: any;
    listening: boolean;
    postDescription:string;
    stoppedByTimeout: boolean;
    selectedTime: Date | null;
    time:string;
    injuredCheckBox:boolean;
    isCalendar:boolean;
    selectedFitnessCertificate: Record<number, string | File | null>;
    leaderShipdata :Array<LeaderShipData>;

    formOneData: FormOneData;
    formTwoData:FormTwoData;
    selectedEyewitnesses:string[]

    savedValues: SavedValues;
    d1FormValue: { teamMembers: { teamMember: string }[] };
    d1PartTwoFormValue:D1PartTwoFormValue;
    functionValueId:string;
    locationV:string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class IncidentController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
  siteAPICallId: string = "";
  functionAPICallId: string = "";
  shiftAPICallId: string = "";
  iirAPICallId: string = "";
  activePesonExpertContractorCallId: string = "";
  getTypeOfInjuryCallId: string = "";
  getBodyPartCallId: string = "";
  getLeaderShipCallId: string = "";
  postFirstFormApicallId: string ="";
  postThreeApicallId: string ="";
  postD1FormApicallId: string ="";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.NavigationPayLoadMessage),
          getName(MessageEnum.SessionResponseMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
        this.state = {
          spokenText: "",
          isLoader:false,
          iirNo: "",
          accountData:[],
          bodyPart:[],
          injuryData: [],
          leaderShipdata:[],
            time: "",
            injuredCheckBox:false,
            listening: false,
            postDescription:"",
            isIncident: false,
            incidentStep: 1,
            isDetails:false,
            categoryData: [
                { id: "1", label: "Electronics" },
                { id: "2", label: "Clothing" },
                { id: "3", label: "Books" },
                { id: "4", label: "Furniture" },
              ],
              reportData: [
                { id: "1", label: "Fatal" },
                { id: "2", label: "Lost Time Incident" },
                { id: "3", label: "Restricted Work Case" },
                { id: "4", label: "Medical Treatment Case" },
                { id: "5", label: "First Aid Case" },
                { id: "6", label: "Near Miss" },
                { id: "7", label: "HiPo Nearmiss" },
                { id: "8", label: "Occupational illness" },
                { id: "9", label: "Dangerous Occurence" },
                { id: "10", label: "Community Incident" }
            ],
            siteData: "" ,
          shiftData: { data: [] },
          functionData: [],
            inicidentRelateImages: [], 
            upldFileLink: "",
            stoppedByTimeout: false,
            selectedTime: new Date(),
            personName: [
                { id: "1", label: "New York" },
                { id: "2", label: "Los Angeles" },
                { id: "3", label: "Chicago" },
                { id: "4", label: "Houston" }
              ],
            locationData: [
                { id: "1", label: "New York" },
                { id: "2", label: "Los Angeles" },
                { id: "3", label: "Chicago" },
                { id: "4", label: "Houston" }
              ],
              isCalendar:false,
              selectedFitnessCertificate:{},
              formOneData: {
                employeeId: "",
                iirNo: "",
                report: "",
                site: "",
                department: "",
                photo: "",
                uploadedImages: "",
                description: "",
                function: "",
              },

              formTwoData:{
                shift: "", time: "", locations: ""
                 
              }, 

              selectedEyewitnesses: [],
              savedValues: {
            injurerds: [
              {
                briefDescription: "",
                injuredPerson: "",
                injured: false,
                employeeId: "",
                joiningDate: "",
                contractorName: "",
                partOfBodyInjured: "",
                typeOfInjury: "",
                details: "",
                expectedDateOfReturn: null,
                eyewitness: "",
                fitnessCertificate: ""
              },
            ],
          },
          d1FormValue: { teamMembers: [{ teamMember: "" }] },
          d1PartTwoFormValue: {
            leader: "",
            expert: "",
            acknowledge: false
          },
          functionValueId: "",
          locationV:""
              
        };
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
      this.getActivePesonExpertContractor();
      this.getFunction();
      this.getShiftData();
      this.getIirNo();
      this.getTypeOfInjury();
      this.getBodyPart();
      this.getleaderShipData();
     };
    
     handleInjuryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({ isDetails: event.target.value === "other" });
    };
    

    async receive(from: string, message: Message) {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
         if (this.isValidResponse(responseJson)) {

          this.apiSucessCall(apiRequestCallId, responseJson);
        }
      }
    }
    
  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data)
      || responseJson.message
      || responseJson.site_location
      || responseJson.sub_functions
      || responseJson.shifts
      || responseJson.iir_code
      || responseJson.accounts
      || responseJson.injury
      || responseJson.body_part
      || responseJson.leadership_user
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.siteAPICallId) {
      this.siteSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.functionAPICallId) {
      this.functionSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.shiftAPICallId) {
      this.shiftSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.iirAPICallId) {
      this.iirSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.activePesonExpertContractorCallId) {
      this.activePesonExpertContractorSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getTypeOfInjuryCallId) {
      this.typeOfInjurySucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getBodyPartCallId) {
      this.bodyPartSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getLeaderShipCallId) {
      this.leaderShipSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postFirstFormApicallId) {
      this.postFirstFormSucessCallBack();
    }
    if (apiRequestCallId === this.postThreeApicallId) {
      this.postThreeSucessCallBack();
    }

    if (apiRequestCallId === this.postD1FormApicallId) {
      this.postD1SucessCallBack();
    }
  };

  apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    alert("Api Fail")
  };

  postD1SucessCallBack = () => {
    this.setState({
      isLoader: false
    });
    this.navigationToAnyPage()
  };
  
  postThreeSucessCallBack = () => {
    this.setState({
      isLoader: false
    })
    this.handleNext();
   };

  postFirstFormSucessCallBack = () => {
    this.setState({
      isLoader: false,
    }
    );
    this.handleNext();
  };

  leaderShipSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      isLoader: false,
      leaderShipdata:responseJson.leadership_user.data
      }
    );
    };


  activePesonExpertContractorSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      isLoader: false,
      accountData: responseJson.accounts
    })
  };

  bodyPartSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      isLoader: false,
      bodyPart:responseJson.body_part
     }
    );
    
   };

  typeOfInjurySucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      isLoader: false,
      injuryData: responseJson.injury
    },
    );
  };
  iirSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      isLoader: false,
      iirNo: responseJson.iir_code
    });
  };

  functionSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      functionData: responseJson.sub_functions.data as FunctionData[],
      isLoader: false
    });
  };

  shiftSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      isLoader: false,
      shiftData: { data: responseJson.shifts.data }
    });
  };


  onFunctionChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    setFieldValue: (field: string, value: string) => void
  ) => {
    const functionValueId = event.target.value as string;  
  
    setFieldValue("function", functionValueId);
  
    this.setState({
      functionValueId: functionValueId
    });
  
    this.getSite(functionValueId);
  };
  

  siteSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      siteData: responseJson.site_location,
      isLoader:false
    })
  }
 

    handleIncidentOpenClose = () => {
        this.setState({ isIncident: !this.state.isIncident, incidentStep: 1 });
    };
    
  navigationToAnyPage = () => {
    window.location.href= ("reportinginvestigation")
  };
   
  
    validationSchema = Yup.object().shape({
        report: Yup.string().required("Report is required"),
        site: Yup.string().required("Site is required"),
        uploadedImages: Yup.string().required("Uploaded Images are required"),
        description: Yup.string().required("Description is required"),
        function: Yup.string().required("Function / Area / BU / Department is required"),

      });

      formTwovalidationSchema = Yup.object().shape({
        shift: Yup.string().required("Shift is required"),
        time: Yup.string().required("Time is required"),
        locations: Yup.string().required("Location is required"),
      });
      

      formThreeValidationSchema = Yup.object().shape({
        injurerds: Yup.array().of(
          Yup.object().shape({
            briefDescription: Yup.string().required("A brief description is required."),
            injuredPerson: Yup.string().required("Please provide the name of the injured person."),
            contractorName: Yup.string().required("Contractor name is required."),
            joiningDate: Yup.date().nullable().required("Joining date is required."),
            partOfBodyInjured: Yup.string().required("Specify the injured body part."),
            typeOfInjury: Yup.string().required("Please describe the type of injury."),
            expectedDateOfReturn: Yup.date().nullable().required("Expected date of return is required."),
          })
        ),
      });
      
      
      formD1ValidationSchema = Yup.object().shape({
        teamMembers: Yup.array().of(
          Yup.object().shape({
            teamMember: Yup.string().required("Team member details are required."),
          })
        ),
      });
  
    formD1PartTwoValidationSchema = Yup.object().shape({
      leader: Yup.string().required("CFT Leader is required"),
      acknowledge: Yup.boolean()
        .oneOf([true], "You must acknowledge before proceeding")
        .required("Acknowledgement is required"),
    });
    
      handleSubmit = () => {
          this.handleNext();
      }; 

      handled1Submit = (values: { teamMembers: { teamMember: string }[] }) => {
        this.setState(
          {
            d1FormValue: values,
          },
          () => {
            this.handleNext();
          }
        );
      };
      handled1PartTwoSubmit = (values: D1PartTwoFormValue) => {
        this.setState(
          {
            d1PartTwoFormValue: values,
          },
          () => {
            this.postD1Api();
          }
        );
      };
      
      
  handleFormThreeSubmit = (values: SavedValues) => {
    this.setState({
      savedValues: values
    }, () => {
      this.postThreeForm()
    })
  }; 

  handleFormOneSubmit = (values: FormOneData) => {
    this.setState({ formOneData: values },()=>{
      this.postFirstForm()
    });
  };

  handleFormTwoSubmit = (values: FormTwoData) => {

    this.setState({ formTwoData: values },()=>{
      this.postFirstForm()
    });

   };

    handleNext = () => {
      if (this.state.incidentStep === 5) {
     this.navigationToAnyPage()

      } else {
        this.setState((prevState) => ({ incidentStep: prevState.incidentStep + 1 }));
    }
  }; 

    handlePrevious = () => {
        this.setState((prevState) => ({ incidentStep: prevState.incidentStep - 1 }));
    };

    handleTimeChange = (time: Date | null) => {
        this.setState({ selectedTime: time });
      };
      handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: FormikHelpers<unknown>["setFieldValue"]
      ) => {
        const files = event.target.files;
        if (files) {
          const newImages: string[] = [];
          for (const file of files) {
            const reader = new FileReader();
            reader.onloadend = () => {
              newImages.push(reader.result as string);
              if (newImages.length === files.length) {
                this.setState(
                  (prevState: { inicidentRelateImages: string[] }) => {
                    const updatedImages = [...prevState.inicidentRelateImages, ...newImages];
                     setFieldValue("uploadedImages", updatedImages);
                    return { inicidentRelateImages: updatedImages };
                  }
                );
              }
            };
            reader.readAsDataURL(file);
          }
        }
      };    

      handleFitnessFileChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if (event.target.files && event.target.files[0]) {
          const file = event.target.files[0];
      
          this.setState((prevState) => ({
            selectedFitnessCertificate: {
              ...prevState.selectedFitnessCertificate,
              [index]: URL.createObjectURL(file),
            },
          }));
        }
      };
      

      apiCall = async (apiData: APIPayloadType) => {
        const { contentType, method, endPoint, body, type } = apiData;
        let token = await getStorageData("authToken")
         const header = {
          "Content-Type": contentType,
          token: token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        )
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        )
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        )
        body && type !== "formData"
          ? requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          )
          : requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
      
      getSite = async (functionValueId: unknown) => {
        {this.setState({isLoader:true})}

         this.siteAPICallId = await this.apiCall({
          contentType: configJSON.notificationsContentType,
          method: configJSON.notificationsApiMethodType,
          endPoint: configJSON.siteEndPoint+functionValueId,
        });
      };
    
      getFunction= async () => {
        {this.setState({isLoader:true})}
        this.functionAPICallId = await this.apiCall({
          contentType: configJSON.notificationsContentType,
          method: configJSON.notificationsApiMethodType,
          endPoint: configJSON.functionEndPoint,
        });
      };
      
      getShiftData= async () => {
        {this.setState({isLoader:true})}
        this.shiftAPICallId = await this.apiCall({
          contentType: configJSON.notificationsContentType,
          method: configJSON.notificationsApiMethodType,
          endPoint: configJSON.shiftEndPoint,
        });
      };

      getIirNo= async () => {    
        
    let machineId = await getStorageData("machineId");
         {this.setState({isLoader:true})}
        this.iirAPICallId = await this.apiCall({
          contentType: configJSON.notificationsContentType,
          method: configJSON.notificationsApiMethodType,
          endPoint: configJSON.iirEndPoint +machineId,
         });
         
      };

      getActivePesonExpertContractor= async () => {    
        {this.setState({isLoader:true})}
        this.activePesonExpertContractorCallId = await this.apiCall({
          contentType: configJSON.notificationsContentType,
          method: configJSON.notificationsApiMethodType,
          endPoint: configJSON.activePesonExpertContractorEndpoint,
         });
      };

  getTypeOfInjury = async () => {
    { this.setState({ isLoader: true }) }
    this.getTypeOfInjuryCallId = await this.apiCall({
      contentType: configJSON.notificationsContentType,
      method: configJSON.notificationsApiMethodType,
      endPoint: configJSON.typeOfInjuryEndpoint,
    });
  };

  getBodyPart = async () => {
    { this.setState({ isLoader: true }) }
    this.getBodyPartCallId = await this.apiCall({
      contentType: configJSON.notificationsContentType,
      method: configJSON.notificationsApiMethodType,
      endPoint: configJSON.bodyPartEndpoint,
    });
  };

  getleaderShipData = async () => {
 
    this.setState({ isLoader: true })
    this.getLeaderShipCallId = await this.apiCall({
      contentType: configJSON.notificationsContentType,
      method: configJSON.notificationsApiMethodType,
      endPoint: configJSON.leaderShipEndpoint,
    });
  };

  handleEyewitnessChange = (value: string) => {
    this.setState((prevState) => ({
      selectedEyewitnesses: prevState.selectedEyewitnesses.includes(value)
        ? prevState.selectedEyewitnesses
        : [...prevState.selectedEyewitnesses, value],
    }));
  };
  
  

  handleRemoveEyewitness = (value: string) => {
    this.setState((prevState) => ({
      selectedEyewitnesses: prevState.selectedEyewitnesses.filter((item) => item !== value),
    }));
  };
  
  postFirstForm = async () => {
    this.setState({isLoader:true})
    const currentLoggedinUser = JSON.parse(sessionStorage.getItem("dataObj") || "{}"); 
    let createPostId = await getStorageData("createPostId");

    const formdata = new FormData();
    formdata.append("employee_code", currentLoggedinUser.Emp_Id);
    formdata.append("iir_no",this.state.formOneData.iirNo);
    formdata.append("reporting", this.state.formOneData.report);
    formdata.append("function_id", this.state.formOneData.function);
    formdata.append("site", this.state.formOneData.site);
    formdata.append("incident_description", this.state.formOneData.description);    
    formdata.append("related_images", this.state.formOneData.uploadedImages  );
    formdata.append("shift", this.state.formTwoData.shift);
    formdata.append("post_time", this.state.formTwoData.time);
    formdata.append("incident_location", this.state.formTwoData.locations);
    formdata.append("report_type", "incident_reporting");

     this.postFirstFormApicallId = await this.apiCall({
      method: configJSON.patchApiMethodType,
      endPoint: configJSON.postFirstFormEndPoint+createPostId,
      type: "formData",
      body: formdata
    });
  };

 renderMic() {
    return this.state.listening ? micStart : mic;
  }

  postThreeForm = async () => {
    const currentLoggedinUser = JSON.parse(sessionStorage.getItem("dataObj") || "{}");
    let createPostId = await getStorageData("createPostId");
     const formdata = new FormData();
    this.state.savedValues.injurerds.forEach((injuredPerson, index) => {
        formdata.append(`injured_person_details[${index}][injured_person_id]`, injuredPerson.injuredPerson);
        formdata.append(`injured_person_details[${index}][is_contract_worker]`, String(injuredPerson.injured));
        formdata.append(`injured_person_details[${index}][employee_id]`, currentLoggedinUser.Emp_Id);
        formdata.append(`injured_person_details[${index}][contractor_name]`, injuredPerson.contractorName);
        formdata.append(`injured_person_details[${index}][joining_date]`, injuredPerson.joiningDate);
         
        (Array.isArray(injuredPerson.partOfBodyInjured) ? injuredPerson.partOfBodyInjured : [injuredPerson.partOfBodyInjured]).forEach((part) => {
            formdata.append(`injured_person_details[${index}][body_injured_ids][]`, part);
        });

        (Array.isArray(injuredPerson.typeOfInjury) ? injuredPerson.typeOfInjury : [injuredPerson.typeOfInjury]).forEach((injury) => {
            formdata.append(`injured_person_details[${index}][injury_ids][]`, injury);
        });

        formdata.append(`injured_person_details[${index}][return_date]`, injuredPerson.expectedDateOfReturn ? injuredPerson.expectedDateOfReturn.toISOString().split("T")[0] : "");
        
      });
      
      formdata.append("id", createPostId);

    this.postThreeApicallId = await this.apiCall({
        method: configJSON.postsApiPostMethodType,
        endPoint: configJSON.postThreeFormEndPoint,
        type: "formData",
        body: formdata
    });
};

postD1Api = async () => {
  let createPostId = await getStorageData("createPostId");
  const formdata = new FormData();
  formdata.append("id", createPostId);

  this.state.d1FormValue.teamMembers.forEach((member: { teamMember: string }, index: number) => {
    formdata.append(`cft_info[${index}]leader_id`, member.teamMember);
    formdata.append(`cft_info[${index}]location_id`, this.state.locationV);
    formdata.append(`cft_info[${index}]function_id`, this.state.functionValueId);
  });

  formdata.append("cft_leader_id", this.state.d1PartTwoFormValue.leader);
  formdata.append("expert_id", this.state.d1PartTwoFormValue.expert);
  formdata.append("acknowledged", String(this.state.d1PartTwoFormValue.acknowledge));

  this.postD1FormApicallId = await this.apiCall({
    method: configJSON.searchApiMethodType,
    endPoint: configJSON.postD1FormEndPoint,
    type: "formData",
    body: formdata
  });
};

handleSpeech = () => {
  const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

  if (!SpeechRecognition) {
    console.error("Speech Recognition API is not supported in this browser.");
    return;
  }

  const recognition = new SpeechRecognition();
  recognition.lang = "en-US";

  this.setState({ listening: true });
  recognition.onstart = () => {
    console.warn("🎙️ Speech recognition started...");
  };

  recognition.onresult = (event: SpeechRecognitionEvent) => {
    const transcript = event.results[0][0].transcript;
    this.setState((prevState) => ({
      spokenText: prevState.spokenText ? `${prevState.spokenText} ${transcript}` : transcript
    }));

    console.warn("📝 Text:", transcript);
  };

  recognition.onerror = (event: Event) => {
    const error = (event as any).error; 
    console.error("❌ Speech recognition error:", error);
    this.setState({ listening: false });
  };

  recognition.onend = () => {
    console.warn("Speech recognition ended.");
    this.setState({ listening: false });
  };

  recognition.start();
};

    // Customizable Area End
}
