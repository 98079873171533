import { InputAdornment, styled, TextField } from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";
import React, { Component } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { webStyle } from "../../blocks/dashboard/src/postCreationPopUp/styles";
import { calendarLogo } from "./assets";

interface Props {
  placeholder: string;
  value: string;
  startDate: Date | null;
  endDate: Date | null;
  monthsShown?: number;
  onChange: (dates: [Date | null, Date | null] | Date | null) => void;
  className?: string;
  startIcon?: SvgIconComponent | string;
  error?: boolean;
  errorMsg?: string;
}

interface State {
  isOpen: boolean;
}

export default class CustomDatePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleChange = (date: Date | null) => {
    this.props.onChange(date);
    this.setState({ isOpen: false });
  };

  handleMultipleMonthChange = (dates: [Date | null, Date | null]) => {
    this.props.onChange(dates);
    if (dates.filter((date) => date !== null).length === 2) {
      this.setState({ isOpen: false });
    }
  };

  render() {
    const {
      placeholder,
      value,
      className = "",
      startIcon: StartIcon,
      error = false,
      errorMsg = "",
      startDate,
      endDate,
      monthsShown = 1,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <Wrapper>
        <TextField
          size="small"
          placeholder={placeholder}
          value={value}
          style={{
            ...webStyle.textareaStyle,
            width: "100%",
            minHeight: "50px !important", 
          }}
          onClick={this.handleToggle}
          className={className}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img
                  src={calendarLogo}
                  alt="Calendar Icon"
                  style={{ cursor: "pointer", width: 24, height: 24 }}
                />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          error={error}
        />
        <ReactDatePicker
          showYearDropdown
          startDate={startDate}
          endDate={endDate}
          onChange={
            monthsShown > 1 ? this.handleMultipleMonthChange : this.handleChange
          }
          onClickOutside={this.handleToggle}
          selectsRange={monthsShown > 1}
          monthsShown={monthsShown}
          open={isOpen}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled("div")(({ theme }) => ({
  "& .react-datepicker__input-container": {
    display: "none",
  },
  "& .react-datepicker": {
    padding: "0 15px",
  },
  "& .react-datepicker-popper": {
    padding: 0,
  },
  "& .react-datepicker__triangle": {
    display: "none",
  },
  "& .react-datepicker__header": {
    backgroundColor: "transparent",
    border: "none",
  },
  "& .react-datepicker__navigation--previous": {
    margin: "15px 25px",
  },
  "& .react-datepicker__navigation--next": {
    margin: "15px 25px",
  },
  "& .react-datepicker__current-month": {
    fontFamily: "clotherRegular",
    fontWeight: 700,
    color: "#0F172A",
    padding: "10px",
  },
  "& .react-datepicker__day-names": {
    fontFamily: "clotherRegular",
  },
  "& .react-datepicker__day-name": {
    padding: "0 5px",
    color: "#64748B",
  },
  "& .react-datepicker__day": {
    fontFamily: "clotherRegular",
    padding: "5px",
    "&:hover": {
      backgroundColor: "#4F9FF873",
      color: "#fff",
      borderRadius: "50%",
    },
  },
  "& .react-datepicker__day--keyboard-selected": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--in-range": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--in-selecting-range": {
    backgroundColor: "#4F9FF8",
    color: "#fff",
    borderRadius: "50%",
  },
  "& .react-datepicker__day--outside-month": {
    backgroundColor: "transparent",
  },
  [theme.breakpoints.down(650)]: {
    "& .react-datepicker": {
      padding: 0,
    },
    "& .react-datepicker__navigation--previous": {
      margin: "10px",
    },
    "& .react-datepicker__navigation--next": {
      margin: "10px",
    },
  },
  [theme.breakpoints.down(630)]: {
    "& .react-datepicker": {
      display: "grid",
    },
  },
}));