import React from "react";
// Customizable Area Start
import { 
    Box,
    Button,
    Divider,
    Grid,
    Snackbar,
    TextField,
    ThemeProvider,
    Typography,
    styled 
} from "@material-ui/core";
import IncidentReportingD8Controller,
{
    ICorrectiveAction,
    IMember,
    IPreventiveAction,
    Props
} from "./IncidentReportingD8Controller.web";
import { theme, Wrapper } from "./RootCauseInvestigation.web";
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikValues } from "formik";
import { webStyle } from "./postCreationPopUp/styles";
import CustomSelect from "../../../components/src/CustomSelect.web";
import { calendarLogo } from "../../../components/src/assets";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import ImageUploader from "../../../components/src/ImageUploader.web";
import Alert from "@material-ui/lab/Alert";
import Loader from "../../../components/src/Loader.web";

const status = [
    { value: 1, name: "Due" },
    { value: 2, name: "Completed" },
    { value: 3, name: "Overdue" },
  ];

// Customizable Area End

export default class IncidentReportingD8 extends IncidentReportingD8Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

    renderEffectivenessVerification = (
        values: FormikValues,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <FieldArray name="corrective_preventive_action.another_members_attributes" data-test-id="fieldArray1">
                {({ remove, push }) => (
                    <>
                        {values.corrective_preventive_action.another_members_attributes.map((member: IMember, index: number) => {
                            return (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography className="fieldText">Effectiveness Verification (next 3 months) </Typography>
                                        { index > 0 && (
                                            <Button
                                                data-test-id="removeBtn"
                                                className="removeBtn"
                                                onClick={() => {
                                                    if(member.id){
                                                        this.setState((prevState) => ({
                                                            delete_another_member_ids: [...prevState.delete_another_member_ids, member.id as number]
                                                          }));
                                                    }
                                                    remove(index)
                                                }}
                                            >
                                               Remove
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Field
                                            data-test-id="verifiedBy"
                                            className="selectField"
                                            style={{
                                                height: "50px"
                                            }}
                                            name="verifiedBy"
                                            as={CustomSelect}
                                            placeholder="Verified By"
                                            options={this.state.leadershipUsers}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`corrective_preventive_action.another_members_attributes[${index}].verified_user_id`, e.target.value)
                                            }}
                                            value={member.verified_user_id}
                                        />
                                        <ErrorMessage name={`corrective_preventive_action.another_members_attributes[${index}].verified_user_id`} component="div" className="errorTxt" />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <Field
                                            data-test-id="verifiedDate"
                                            name="verifiedDate"
                                            className="inputField"
                                            size="small"
                                            as={CustomDatePicker}
                                            placeholder="Verified Date"
                                            ageRestriction={18}
                                            endIcon={calendarLogo}
                                            onChange={(date: Date) =>
                                                setFieldValue(`corrective_preventive_action.another_members_attributes[${index}].verified_date`, date.toLocaleDateString("en-GB"))
                                            }
                                            value={member.verified_date}
                                        />
                                        <ErrorMessage name={`corrective_preventive_action.another_members_attributes[${index}].verified_date`} component="div" className="errorTxt" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            data-test-id="Status"
                                            className="selectField"
                                            name="Status"
                                            as={CustomSelect}
                                            placeholder="Status"
                                            selectByName={true}                                     
                                            options={status}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`corrective_preventive_action.another_members_attributes[${index}].status`, e.target.value)
                                            }}
                                            value={member.status}
                                        />
                                        <ErrorMessage name={`corrective_preventive_action.another_members_attributes[${index}].status`} component="div" className="errorTxt" />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            data-test-id="remark"
                                            style={{
                                                ...webStyle.textareaStyle,
                                                width: "97%",
                                                color: "#DCDCDC",
                                            }}
                                            placeholder="Remark"
                                            maxRows={3}
                                            minRows={3}
                                            multiline
                                            value={member.remark}
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue(`corrective_preventive_action.another_members_attributes[${index}].remark`, event.target.value)
                                            }
                                            className="textarea"
                                            name="remark"
                                        />
                                        <ErrorMessage name={`corrective_preventive_action.another_members_attributes[${index}].remark`} component="div" className="errorTxt" />
                                    </Grid>
                                </Grid>
                            )
                        })}
                                            <Button
                                                data-test-id="addAnotherVerifyAction"
                                                className="addCauseButton"
                                                style={{
                                                    width: "50%"
                                                }}
                                                onClick={() => push({
                                                    id: null,
                                                    verified_user_id: "",
                                                    verified_date: "",
                                                    status: "",
                                                    remark: "",
                                                })}>
                                                + Add another Implement / Verify Action
                                            </Button>
                    </>
                )}
            </FieldArray>
        )
    };

    renderTeamRecognition = (
        values: FormikValues,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }
    ) => {
        return (
            <>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                        <Box>
                            <Typography className="fieldText">Lessons Learnt</Typography>
                        </Box>
                        <Box>
                            <img
                                height="21px"
                                width="21px"
                                src={this.renderMic()}
                                alt="mic icon"
                                onClick={async () => {
                                    await this.toggleListening();
                                    this.setMikeData(setFieldValue)
                                }}
                                data-test-id="speechText"
                            />
                        </Box>
                    </Box>
                    <TextField
                        data-test-id="lessonLearnt"
                        style={{
                            ...webStyle.textareaStyle,
                            width: "97%"
                        }}
                        placeholder="Brief Description"
                        maxRows={3}
                        minRows={3}
                        multiline
                        value={values.corrective_preventive_action.description}
                        InputProps={{ disableUnderline: true }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("corrective_preventive_action.description", event.target.value)
                        }
                        className="textarea"
                        name="description"
                    />
                    <ErrorMessage name="corrective_preventive_action.description" component="div" className="errorTxt" />
                </Grid>

                <Grid item xs={12}>
                    <Divider style={{ margin: "24px 0px" }} />
                </Grid>

                <Grid item xs={12}>
                    {this.renderEffectivenessVerification(values, setFieldValue)}
                </Grid>
            </>
        );
    };

    renderCorrectiveAction = (
        values: FormikValues,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <FieldArray name="corrective_preventive_action.another_corrective_actions_attributes" data-test-id="fieldArray2">
                {({ remove, push }) => (
                    <>
                        {values.corrective_preventive_action.another_corrective_actions_attributes.map((correctiveAction: ICorrectiveAction, index: number) => {
                            return (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography className="fieldText">Corrective Action</Typography>
                                        { index > 0 && (
                                            <Button
                                                data-test-id="removeBtn2"
                                                className="removeBtn"
                                                onClick={() => {
                                                    if(correctiveAction.id){
                                                        this.setState((prevState) => ({
                                                            delete_another_corrective_action_ids: [...prevState.delete_another_corrective_action_ids, correctiveAction.id as number]
                                                          }));
                                                    }
                                                    remove(index)
                                                }}
                                            >
                                               Remove
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            data-test-id="correctiveDescription"
                                            style={{
                                                ...webStyle.textareaStyle,
                                                width: "97%",
                                                color: "#DCDCDC",
                                            }}
                                            placeholder="Brief Description"
                                            maxRows={3}
                                            minRows={3}
                                            multiline
                                            value={correctiveAction.description}
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue(`corrective_preventive_action.another_corrective_actions_attributes[${index}].description`, event.target.value)
                                            }
                                            className="textarea"
                                            name="description"
                                        />
                                        <ErrorMessage name={`corrective_preventive_action.another_corrective_actions_attributes[${index}].description`} component="div" className="errorTxt" />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Field
                                            data-test-id="assignedPersonForCorrectiveAction"
                                            className="selectField"
                                            name="assignedPersonForCorrectiveAction"
                                            as={CustomSelect}
                                            placeholder="Assign Person for Corrective Action"
                                            options={this.state.leadershipUsers}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`corrective_preventive_action.another_corrective_actions_attributes[${index}].assigned_person_id`, e.target.value)
                                            }}
                                            value={correctiveAction.assigned_person_id}
                                        />
                                        <ErrorMessage name={`corrective_preventive_action.another_corrective_actions_attributes[${index}].assigned_person_id`} component="div" className="errorTxt" />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <Field
                                            data-test-id="dueDateCorrectiveAction"
                                            name="dueDateCorrectiveAction"
                                            className="inputField"
                                            size="small"
                                            as={CustomDatePicker}
                                            placeholder="Due Date"
                                            endIcon={calendarLogo}
                                            onChange={(date: Date) =>
                                                setFieldValue(`corrective_preventive_action.another_corrective_actions_attributes[${index}].due_date`, date.toLocaleDateString("en-GB"))
                                            }
                                            value={correctiveAction.due_date}
                                        />
                                        <ErrorMessage name={`corrective_preventive_action.another_corrective_actions_attributes[${index}].due_date`} component="div" className="errorTxt" />
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <Button
                            data-test-id="addAnotherCorrectiveAction"
                            className="addCauseButton"
                            style={{ 
                                width: "50%"
                            }}
                            onClick={() => push({
                                id: null,
                                description: "",
                                assigned_person_id: "",
                                due_date: "",
                            })}>
                             + Add another Corrective Action
                        </Button>
                    </>
                )}
            </FieldArray>
        )
    };

    renderPreventiveAction = (
        values: FormikValues,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }) => {
        return (
            <FieldArray name="corrective_preventive_action.preventive_actions_attributes" data-test-id="fieldArray3">
                {({ remove, push }) => (
                    <>
                        {values.corrective_preventive_action.preventive_actions_attributes.map((preventiveAction: IPreventiveAction, index: number) => {
                            return (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography className="fieldText">Preventive Action</Typography>
                                        { index > 0 && (
                                            <Button
                                                data-test-id="removeBtn2"
                                                className="removeBtn"
                                                onClick={() => {
                                                    if(preventiveAction.id){
                                                        this.setState((prevState) => ({
                                                            delete_preventive_action_ids: [...prevState.delete_preventive_action_ids, preventiveAction.id as number]
                                                          }));
                                                    }
                                                    remove(index)
                                                }}
                                            >
                                               Remove
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            data-test-id="preventiveDescription"
                                            style={{
                                                ...webStyle.textareaStyle,
                                                width: "97%",
                                                color: "#DCDCDC",
                                            }}
                                            placeholder="Brief Description"
                                            maxRows={3}
                                            minRows={3}
                                            multiline
                                            value={preventiveAction.description}
                                            InputProps={{ disableUnderline: true }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                setFieldValue(`corrective_preventive_action.preventive_actions_attributes[${index}].description`, event.target.value)
                                            }
                                            className="textarea"
                                            name="description"
                                        />
                                        <ErrorMessage name={`corrective_preventive_action.preventive_actions_attributes[${index}].description`} component="div" className="errorTxt" />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <Field
                                            data-test-id="assignedPersonForPreventiveAction"
                                            className="selectField"
                                            name="assignedPersonForPreventiveAction"
                                            as={CustomSelect}
                                            placeholder="Assign Person for Preventive Action"
                                            options={this.state.leadershipUsers}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue(`corrective_preventive_action.preventive_actions_attributes[${index}].assigned_person_id`, e.target.value)
                                            }}
                                            value={preventiveAction.assigned_person_id}
                                        />
                                        <ErrorMessage name={`corrective_preventive_action.preventive_actions_attributes[${index}].assigned_person_id`} component="div" className="errorTxt" />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <Field
                                            data-test-id="dueDatePreventiveAction"
                                            name="dueDatePreventiveAction"
                                            className="inputField"
                                            size="small"
                                            as={CustomDatePicker}
                                            placeholder="Due Date"
                                            endIcon={calendarLogo}
                                            onChange={(date: Date) =>
                                                setFieldValue(`corrective_preventive_action.preventive_actions_attributes[${index}].due_date`, date.toLocaleDateString("en-GB"))
                                            }
                                            value={preventiveAction.due_date}
                                        />
                                        <ErrorMessage name={`corrective_preventive_action.preventive_actions_attributes[${index}].due_date`} component="div" className="errorTxt" />
                                    </Grid>
                                </Grid>
                            )
                        })}
                        <Button
                            data-test-id="addAnotherPreventiveAction"
                            className="addCauseButton"
                            style={{ 
                                width: "50%"
                            }}
                            onClick={() => push({
                                id: null,
                                description: "",
                                assigned_person_id: "",
                                due_date: "",
                            })}>
                             + Add another Preventive Action
                        </Button>
                    </>
                )}
            </FieldArray>
        )
    };

    renderActionAssignment = (
        values: FormikValues,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }
    ) => {
        return (
            <>
                <Grid item xs={12}>
                    {this.renderCorrectiveAction(values, setFieldValue)}
                </Grid>

                <Grid item xs={12}>
                    {this.renderPreventiveAction(values, setFieldValue)}
                </Grid>
            </>
        );
    };

    renderPostCreation = (
        values: FormikValues,
        setFieldValue: {
            (field: string,
                value: any,
                shouldValidate?: boolean | undefined): void; (arg0: string, arg1: string): void;
        }
    ) => {
        return (
            <>
                <Grid item xs={12} sm={12} md={6}>
                    <Typography className="fieldText">Site</Typography>
                    <Field
                        data-test-id="site"
                        className="selectField"
                        name="site"
                        as={CustomSelect}
                        placeholder="Site"
                        options={this.state.functionData}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.getWorkLocationData(e.target.value);
                            setFieldValue("site", e.target.value);
                        }}
                        value={values.site}
                    />
                    <ErrorMessage name="site" component="div" className="errorTxt" />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Typography className="fieldText">Function / Area / BU / Department</Typography>
                    <Field
                        data-test-id="department"
                        className="selectField"
                        name="department"
                        as={CustomSelect}
                        placeholder="Function / Area / BU / Department"
                        options={this.state.workLocationData}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("department", e.target.value)
                        }}
                        value={values.department}
                    />
                    <ErrorMessage name="department" component="div" className="errorTxt" />
                </Grid>

                <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" mb={1}>
                        <Box>
                            <Typography className="fieldText">Brief Description</Typography>
                        </Box>
                        <Box>
                            <img
                                height="21px"
                                width="21px"
                                src={this.renderMic()}
                                alt="mic icon"
                                onClick={async () => {
                                    await this.toggleListening();
                                    this.setMikeData(setFieldValue)
                                }}
                                data-test-id="speechText"
                            />
                        </Box>
                    </Box>
                    <TextField
                        data-test-id="briefDescription"
                        style={{
                            ...webStyle.textareaStyle,
                            width: "97%"
                        }}
                        placeholder="Brief Description"
                        maxRows={3}
                        minRows={3}
                        multiline
                        value={values.description}
                        InputProps={{ disableUnderline: true }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue("description", event.target.value)
                        }
                        className="textarea"
                        name="description"
                    />
                    <ErrorMessage name="description" component="div" className="errorTxt" />
                </Grid>

                <Grid item xs={12}>
                    <Typography className="fieldText">Related Photo / Sketch</Typography>
                    <ImageUploader data-test-id="imageUploader" images={this.state.images} onUpload={(files: FileList) => this.handleUpload(files, setFieldValue)} onRemove={(index: number) => this.handleRemove(index, setFieldValue)} />
                    <ErrorMessage name="relatedPhotos" component="div" className="errorTxt" />
                </Grid>
            </>
        );
    };

    renderSnackbar() {
          const { responseStatus, errorMsg } = this.state;
          if (!errorMsg) return null;
        
          return (
            <Snackbar
              data-test-id="snackbar"
              open={responseStatus !== null}
              onClose={this.handleCloseSnackbar}
              autoHideDuration={3000}
            >
              <Alert
                onClose={this.handleCloseSnackbar}
                severity={responseStatus || undefined}
              >
                {errorMsg}
              </Alert>
            </Snackbar>
          );
        }


  render() {
    const { showScreen } = this.state;
    return (
        <ThemeProvider theme={theme}>
            <Loader loading={this.state.loading} />
            { this.renderSnackbar() }
            <Wrapper>
                <Box className="headingTxtWrapper">
                    <Typography data-test-id="titleText" className="headingTxt">D8: {showScreen}</Typography>
                </Box>
                <MainContainer>
                { showScreen !== "Post Creation" ? (
                        <Formik
                            enableReinitialize={true}
                            validateOnBlur={true}
                            validateOnChange={true}
                            data-test-id="dEightFormTestId"
                            initialValues={this.state.formValues}
                            validationSchema={() => this.formD8Validation(showScreen)}
                            onSubmit={(values, { setTouched }) => {
                                setTouched({});
                                this.handleSubmitD8From(values);
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form translate={undefined}>
                                    <Grid container spacing={2} alignItems="center">
                                        {showScreen === "Team Recognition" && this.renderTeamRecognition(values, setFieldValue)}
                                        {showScreen === "Action Assignment" && this.renderActionAssignment(values, setFieldValue)}
                                        <Grid item xs={12}>
                                            <Box className="actionButtons" style={{ padding: 0 }}>
                                                <Box>
                                                    <Button 
                                                        data-test-id="prevButton"
                                                        className="prevButton"
                                                        onClick={() => this.handlePrevClick()}
                                                    >
                                                        Previous
                                                    </Button>
                                                </Box>
                                                <Box
                                                    style={{
                                                        gap: "20px",
                                                        display: "flex",
                                                    }}>
                                                    <Button
                                                        className="saveButton"
                                                        data-test-id="saveButton"
                                                        type="submit"
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button
                                                        data-test-id="nextButton"
                                                        className="nextButton"
                                                        type="submit"
                                                        onClick={() => this.handleNext()}
                                                    >Next
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                ): (
                    <Formik
                            enableReinitialize={true}
                            validateOnBlur={true}
                            validateOnChange={true}
                            data-test-id="dEightPostCreationTestId"
                            initialValues={this.state.post_creation}
                            validationSchema={this.formD8PostCreationValidation}
                            onSubmit={this.handleSubmitD8PostCreationFrom}
                        >
                            {({ values, setFieldValue }) => (
                                <Form translate={undefined}>
                                    <Grid container spacing={2} alignItems="center">
                                        {showScreen === "Post Creation" && this.renderPostCreation(values, setFieldValue)}
                                        <Grid item xs={12}>
                                            <Box className="actionButtons" style={{ padding: 0 }}>
                                            <Box></Box>
                                                <Box
                                                    style={{
                                                        gap: "20px",
                                                        display: "flex",
                                                    }}>
                                                    <Button
                                                        className="saveButton"
                                                        data-test-id="saveButton1"
                                                        type="submit"
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button
                                                        data-test-id="submitButton"
                                                        className="nextButton"
                                                        type="submit"
                                                        //onClick={() => this.handleNext()}
                                                    >Submit
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                )}
                </MainContainer>
            </Wrapper>
        </ThemeProvider>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const MainContainer = styled(Box)({
    padding: "30px",
    "& .removeBtn": {
        color: "#8735E1",
        textTransform: "none",
        fontSize: "14px",
        fontFamily: "ClotherRegular",
        fontWeight: 700,
        lineHeight: "22px",
        display: "flex",
        alignItems: "center",
        '&:hover': {
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
    },


    "& .selectField .inputField": {
        width: "100%",
        height: "50px !important",
    },

    "& [class*=MuiInputBase-root]": {
        height: "50px !important",
    },

    "& [class*=MuiInputBase-input]": {
        //color: "#DCDCDC",
    },

    "& .MuiInputBase-input::placeholder": {
        color: "#686868",
    },

    "& [class*=MuiTextField-root]": {
        width: "100%",
        height: "50px !important",
        margin: "0px !important",
        "& .MuiInputBase-input": {
            padding: "0px !important",
        }
    }
})
// Customizable Area End