import React from "react";
// Customizable Area Start
import { 
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider, 
    Typography, 
    createTheme, 
    styled 
} from "@material-ui/core";
import SafetyInteractionSelectionController,
{
    Props
} from "./SafetyInteractionSelectionController.web";
import { NavigateNext } from "@material-ui/icons";
import { webStyles } from "./SafetyInteraction.web";

const theme = createTheme({
    typography: {
        h2: {
            fontFamily: "ClotherRegular",
            fontSize: "26px",
            fontWeight: 700,
            lineHeight: "32px",
            color: "#2B2A29",
        },
        h6: {
            fontFamily: "ClotherRegular",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "22px",
            color: "#18181E",
        },
        subtitle2: {
            fontFamily: "ClotherRegular",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "22px",
            color: "#2B2A29",
        }
    },
    overrides: {
        MuiRadio: {
            colorSecondary: {
                '&$checked': {
                  color: "#9519EF",
              },
              }
        },
        MuiCheckbox: {
            colorSecondary: {
                '&$checked': {
                  color: "#9519EF",
              },
              }
        }
    }
});
  

  // Customizable Area End

  // Customizable Area Start
export default class SafetyInteractionSelection extends SafetyInteractionSelectionController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
        <ThemeProvider theme={theme}>
            <MainContainer>
                <Box mb={2} style={webStyles.breadcrumbWrapper1}>
                    <Typography
                        style={webStyles.breadcrumbItem1}
                        data-test-id="navigateSafetyInteraction"
                    //onClick={() => this.handleNavigation("SafetyInteraction")}
                    >Safety Interaction</Typography>
                    <NavigateNext style={webStyles.navigateIcon1} />
                    <Typography style={webStyles.breadcrumbItem1}>
                        Safety Interaction Number
                    </Typography>
                    <NavigateNext style={webStyles.navigateIcon1} />
                    <Typography style={webStyles.breadcrumbActiveItem1}>
                        Selection
                    </Typography>
                </Box>
                <ThemeProvider theme={theme}>
                    <Box mb={2}>
                        <Typography variant="h2" className="titleText" data-test-id="titleText">
                            Safety Interaction
                        </Typography>
                    </Box>
                    <Container className="container">
                        <Box>
                            {this.state.observationCategoriesData.map((obs, categoryIndex) => (
                                <Box key={categoryIndex}>
                                    <Box className="sectionTitle">
                                        <Typography variant="h6" gutterBottom>
                                            {obs.section}
                                        </Typography>
                                        </Box>
                                    <Box className="tableWrapper">
                                        <TableContainer className="tableContainer">
                                            <Table className="tableEle">
                                                <TableHead className="tableHeader">
                                                    <TableRow>
                                                        <TableCell className="tableHeaderCell" style={{ width: "255px" }}>Title</TableCell>
                                                        <TableCell className="tableHeaderCell">Select Appropriate Option</TableCell>
                                                        <TableCell className="tableHeaderCell">Check If Positive Action Done</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {obs.items.map((item, itemIndex) => (
                                                        <TableRow key={item.id}>
                                                            <TableCell className="tableRowCell" style={{ width: "255px" }}>
                                                                <Typography variant="subtitle2" style={{ marginLeft: "8px" }}>
                                                                    {item.name}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className="tableRowCell">
                                                                <RadioGroup 
                                                                    row 
                                                                    value={item.status}
                                                                    data-test-id="radioBtn"
                                                                    onChange={(e) => this.handleRadioChange(categoryIndex, itemIndex, e.target.value)}
                                                                    className="radioGroup">
                                                                    <FormControlLabel value="Safe" control={<Radio color="secondary" />} label="Safe" />
                                                                    <FormControlLabel value="At Risk" control={<Radio color="secondary" />} label="At Risk" />
                                                                </RadioGroup>
                                                            </TableCell>
                                                            <TableCell className="tableRowCell">
                                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                                    <Checkbox
                                                                        checked={item.positive_action}
                                                                        data-test-id="checkbox"
                                                                        onChange={() => this.handleCheckboxChange(categoryIndex, itemIndex)}
                                                                        color="secondary"
                                                                    />
                                                                    <Typography variant="subtitle2" style={{ marginLeft: "8px" }}>Positive Actions</Typography>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
      ))}
                        </Box>
                    </Container>
                    <Box className="actionButtons" >
                        <Box>
                            <Button 
                                className="prevButton"  
                            >Previous</Button>
                        </Box>
                        <Box style={{ display: "flex", gap: "20px" }}>
                            <Button 
                                className="saveButton" 
                                data-test-id="saveButton" 
                            >Save</Button>
                            <Button 
                                className="nextButton" 
                                data-test-id="saveButton2"
                            >Next</Button>
                        </Box>
                    </Box>
                </ThemeProvider>
            </MainContainer>
        </ThemeProvider>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const MainContainer = styled(Box)({
    height: "100vh",
    backgroundColor: "#F3F3F3",
    padding: "30px",

    "& .container": {
        background: "#fff",
        boxShadow: "4px 0px 4px 0px rgba(0, 0, 0, 0.02)",
        borderRadius: "8px",
        padding: "0px",
        paddingBottom: "30px",
        marginBottom: "30px",
        margin: "0px",
      },

      "& .sectionTitle": {
        backgroundColor: "#FAF3FD", 
        height: "60px", 
        display: "flex", 
        alignItems: "center",
        paddingLeft: "30px",
        marginBottom: "30px",
      },

      "& .tableWrapper": {
        marginBottom: "20px",
        padding: "0px 30px",
        boxSizing: "border-box",
      },

      "& .tableContainer": {
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      },

      "& .tableEle": {
        backgroundColor: "#F8F8F8 !important",
        borderBottom: "1px solid #EEEEEE",
        borderRadius: "8px",
      },
    
        "& .tableHeader": {
            backgroundColor: "#6a0dad",
            border: "1px solid transperant",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
        },


      "& .tableHeaderCell":  {
          fontFamily: "'ClotherRegular'",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "18px",
          color: "#FFFFFF",
      },

      "& .tableRowCell": {
        color: "#2B2A29",
        fontFamily: "'ClotherRegular'",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "22px",
        padding: "12px",
      },

      "& .tableRow": {
        backgroundColor: "#F8F8F8",
        border: "0.5px solid #EEEEEE",
      },
    
      "& .radioGroup": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      },


      "& .actionButtons": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
        paddingBottom: "40px",
      },
      "& .prevButton, .saveButton": {
          height: "50px",
          width: "160px",
          border: "1px solid #8735E1",
          textTransform: "none",
          color: "#8735E1",
          borderRadius: "8px",
          fontSize: "20px",
          fontFamily: "'ClotherRegular'",
          lineHeight: "22px",
          fontWeight: 700,
      },
      "& .nextButton": {
          height: "50px",
          width: "160px",
          fontFamily: "'ClotherRegular'",
          color: "#FFFFFF",
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "22px",
          borderRadius: "8px",
          backgroundColor: "#8735E1",
          textTransform: "none",
      },
})
// Customizable Area End