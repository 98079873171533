import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { sendAPIRequest } from "../../../../components/src/Utils";
// Customizable Area Start
export const configJSON = require("../config");

interface ISafetyInteraction {
  id: number;
  interaction_number: string;
  target_date: string | null;
  status: string;
  s1_observed: boolean;
  s2_observed: boolean;
  s3_observed: boolean;
  s4_observed: boolean;
  s5_observed: boolean;
  s6_observed: boolean;
  reason_not_able_observe: string | null;
  start_time: string | null;
  end_time: string | null;
  duration: string | null;
  location_id: number | null;
  assignee_id: number;
  reviewer_id: number;
  creator_id: number;
  created_at: string;
  updated_at: string;
  safe_observation_count: number | null;
  safe_observation_per_hour: number | null;
  safe_observation_per_day: number | null;
  safe_observation_per_year: number | null;
  at_risk_observation_count: number | null;
  at_risk_observation_per_hour: number | null;
  at_risk_observation_per_day: number | null;
  at_risk_observation_per_year: number | null;
  not_able_to_observe_count: number | null;
  not_able_to_observe_per_hour: number | null;
  not_able_to_observe_per_day: number | null;
  not_able_to_observe_per_year: number | null;
  positive_action_count: number | null;
  positive_action_per_hour: number | null;
  positive_action_per_day: number | null;
  positive_action_per_year: number | null;
  system_action_count: number | null;
  system_action_per_hour: number | null;
  system_action_per_day: number | null;
  system_action_per_year: number | null;
};

type ObservationCategoriesLabel =
    | "Reaction of People"
    | "Position of People"
    | "Personal Protective Equipment"
    | "Tools & Equipment"
    | "Procedure"
    | "Housekeeping";

interface IObservationCategories {
  category: string;
  label: ObservationCategoriesLabel;
  observed: boolean;
}
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  observationCategories: IObservationCategories[];
  // Customizable Area End
}
interface SS {}

export default class SafetyInteractionObservationCategoriesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  callGetSafetyInteraction: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      observationCategories: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.safetyInteractionById();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON);
    // Customizable Area End
  }

  // Customizable Area Start
  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: Record<string, unknown>
  ) => {
    const successCallbackMap = {
        [this.callGetSafetyInteraction]: this.handleGetSafetyInteractionApiResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJSON: Record<string, unknown>) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJSON);
    }
  };

  handleGetSafetyInteractionApiResponse = (responseJSON: Record<string, unknown>) => {
    if (this.handleErrorResponse(responseJSON)) {
      return;
    }

    const response = responseJSON as {
      safety_interaction: ISafetyInteraction;
    };

    if(response.safety_interaction){
      let attributes = response.safety_interaction;
      let observationCategories: IObservationCategories[]  = [
        { category: "S1", label: "Reaction of People", observed: attributes.s1_observed },
        { category: "S2", label: "Position of People", observed: attributes.s2_observed },
        { category: "S3", label: "Personal Protective Equipment", observed: attributes.s3_observed },
        { category: "S4", label: "Tools & Equipment", observed: attributes.s4_observed },
        { category: "S5", label: "Procedure", observed: attributes.s5_observed },
        { category: "S6", label: "Housekeeping", observed: attributes.s6_observed },
      ];

      this.setState({ observationCategories });
    }
  };

  handleErrorResponse = (responseJSON: Record<string, unknown>) => {
    const {
      errors: possibleErrors
    } = responseJSON;

    if (possibleErrors) {
      return true;
    }
    return false;
  };

  safetyInteractionById = async () => {
    const safetyInteractionId = await getStorageData("safetyInteractionId");
    const token = await getStorageData("authToken");

    this.callGetSafetyInteraction = sendAPIRequest(
      `${configJSON.safetyInteractionByIdApiEndPoint}/${safetyInteractionId}`,
      {
        method: configJSON.validationApiMethodType,
        headers: {
          token,
        },
      }
    );
  };

  handleNavigation = (route: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  // Customizable Area End
}

